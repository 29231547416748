import React, {
  KeyboardEvent,
  useCallback,
  FocusEvent,
  useMemo,
  useRef,
  useEffect,
  useState,
} from 'react';
import { TextField, TextFieldProps, TextFieldVariants } from '@mui/material';
import { useAppContext } from '../store/ui';
import { getInputTextWidth } from '../helpers/dom.helpers';
import { omit } from '../../../shared/helpers/object_helpers';
import { useObjectMemo } from '../hooks/object.hook';

const DEFAULT_WIDTH = '100%';
const MIN_AUTO_WIDTH = 80;

type NodonTextFieldProps<T extends TextFieldVariants = TextFieldVariants> =
  TextFieldProps<T> & {
    autoWidth?: boolean;
    bold?: boolean;
  };

const NodonTextField: React.FC<NodonTextFieldProps> = (textFieldProps) => {
  const appContext = useAppContext();
  const { onBlur, onFocus, InputProps, autoWidth } = textFieldProps;
  const inputProps = InputProps?.inputProps;
  const ref = useRef<HTMLInputElement>(null);
  const inputRef: React.RefObject<HTMLInputElement> | undefined =
    typeof textFieldProps.inputRef !== 'function'
      ? (textFieldProps.inputRef ?? ref)
      : ref;
  const [width, setWidth] = useState<string>('auto');

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e);
      }
      appContext({ inputFocused: true });
    },
    [onFocus, appContext],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }
      appContext({ inputFocused: false });
    },
    [onBlur, appContext],
  );

  useEffect(() => {
    if (autoWidth && inputRef.current) {
      setWidth(
        `${Math.max(MIN_AUTO_WIDTH, getInputTextWidth(inputRef.current) + 8 * 2)}px`,
      );
    } else {
      setWidth(DEFAULT_WIDTH);
    }
  }, [autoWidth, inputRef, textFieldProps.value, textFieldProps.placeholder]);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current && textFieldProps.autoFocus) {
        inputRef.current.select();
      }
    });
  }, [inputRef, textFieldProps.autoFocus]);

  const customInputProps: TextFieldProps['InputProps'] = useMemo(
    () => ({
      ...InputProps,
      inputProps: {
        ...inputProps,
        onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => {
          if (inputProps?.onKeyDown) {
            inputProps?.onKeyDown(event);
            return;
          }

          if (event.key === 'Enter') {
            event.currentTarget.blur();
            event.preventDefault();
            event.stopPropagation();
          }
        },
        style: {
          fontWeight: textFieldProps.bold ? 700 : undefined,
          ...inputProps?.style,
        },
      },
    }),
    [InputProps, inputProps, textFieldProps.bold],
  );

  const sx: TextFieldProps['sx'] = useObjectMemo({
    ...textFieldProps.sx,
    width,
  });

  return (
    <TextField
      {...omit(textFieldProps, 'autoWidth', 'bold')}
      sx={sx}
      inputRef={inputRef}
      InputProps={customInputProps}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

export default NodonTextField;
