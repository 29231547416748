import { Box, List, SxProps, TextFieldProps } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useSearch } from '../../../hooks/search.hooks';
import { NodonTheme } from '../../../style';
import SearchField from '../../SearchField';
import VirtualizedItems from '../../VirtualizedItems';
import {
  SelectListChildren,
  SelectMenuWidhts,
  SelectMenuWidth,
} from '../menu.model';
import { makeStyles } from 'tss-react/mui';
import { ListChildComponentProps } from 'react-window';
import EmptyState from '../../EmptyState';

interface SelectListProps<T> {
  items: T[];
  enableSearch?: boolean;
  enableVirtualization?: boolean;
  width?: SelectMenuWidth | number;

  /** If there is no items, show this message */
  emptyStateMessage?: string;

  /** Filter items based on this string */
  searchString?: string;

  onSearch?: (searchString: string) => void;
  children: SelectListChildren<T>;
}

const SelectList = <T,>({
  items,
  enableSearch = false,
  enableVirtualization = false,
  width = 'small',
  searchString,
  emptyStateMessage,
  onSearch,
  children,
}: SelectListProps<T>) => {
  const { classes } = useStyles();

  const [localSearchString, setLocalSearchString] = useState(searchString);
  const filteredItems = useSearch(items, localSearchString);

  const searchFieldSlotProps = useMemo<TextFieldProps['slotProps']>(
    () => ({
      input: {
        classes: { notchedOutline: classes.notchedOutline },
      },
      htmlInput: {
        sx: { padding: '10px 14px' },
      },
    }),
    [classes.notchedOutline],
  );

  const searchFieldStyles = useMemo<SxProps>(() => {
    return {
      borderBottom: '1px solid',
      borderColor:
        filteredItems.length > 0
          ? NodonTheme.palette.neutral.lightest
          : 'transparent',
    };
  }, [filteredItems.length]);

  const listSxProps = useMemo<SxProps>(() => {
    return {
      width: SelectMenuWidhts[width],
      overflow: 'auto',
    };
  }, [width]);

  const handleSearch = useCallback(
    (searchString: string) => {
      if (onSearch) {
        onSearch(searchString);
      }
      setLocalSearchString(searchString);
    },
    [onSearch, setLocalSearchString],
  );

  const renderChildren = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const item = filteredItems[index];
      return item ? children(item, style) : null;
    },
    [children, filteredItems],
  );

  return (
    <>
      {emptyStateMessage && filteredItems.length <= 0 && (
        <EmptyState message={emptyStateMessage} />
      )}
      {enableSearch && (
        <SearchField
          autoFocus
          fullWidth
          value={localSearchString}
          onChange={handleSearch}
          sx={searchFieldStyles}
          slotProps={searchFieldSlotProps}
        />
      )}
      {enableVirtualization ? (
        <Box flex={1}>
          <VirtualizedItems items={filteredItems}>
            {renderChildren}
          </VirtualizedItems>
        </Box>
      ) : (
        <List sx={listSxProps}>
          {filteredItems.map((item, index) => children(item, undefined, index))}
        </List>
      )}
    </>
  );
};

const useStyles = makeStyles()(() => ({
  notchedOutline: {
    border: 0,
  },
}));

export default SelectList;
