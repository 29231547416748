import React, { CSSProperties, FC, useCallback, useMemo } from 'react';
import {
  Box,
  ListItemIcon,
  Checkbox,
  SxProps,
  Theme,
  BoxProps,
  Tooltip,
} from '@mui/material';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import { useObjectMemo } from '../../../hooks/object.hook';
import SelectMenu from '../SelectList/SelectMenu';
import {
  useMenuPosition,
  menuMouseHandler,
  openMenu as openContextMenu,
  closeMenu,
} from '../../../hooks/menu.hooks';
import { EllipsisText } from '../../EllipsisText';
import KebabButton from './KebabButton';
import { hasDefinedProperties } from '../../../../../shared/helpers/object_helpers';
import SelectMenuNestedItem from '../ListItems/SelectMenuNestedItem';
import {
  KebabButtonProps,
  KebabMenuProps,
  NodonMenuItemProps,
  SelectListChildren,
} from '../menu.model';
import { KeyboardArrowRight } from '@mui/icons-material';
import { NodonTheme } from '../../../style';

interface Props extends KebabMenuProps, Omit<BoxProps, 'id'> {
  /**
   * If no items are provided, the kebab menu will be disabled
   */
  items: NodonMenuItemProps[];
  id: string | number;
}

export const KebabMenu: FC<Props> = ({
  items,
  id,
  anchor,
  hidden,
  menuWidth,
  tooltip,
  buttonProps,
  onClose,
  onOpen,
  ...boxProps
}) => {
  if (!id) {
    throw new Error('KebabMenu: key is required');
  }
  const position = useMenuPosition(id);

  const openKebab: KebabButtonProps['onClick'] = useMouseEventCallback(
    (e) => {
      const anchorPosition = menuMouseHandler(e);
      const isDefinedPosition = hasDefinedProperties(
        anchorPosition,
        'top',
        'left',
      );
      if (isDefinedPosition) {
        openContextMenu({ anchorPosition, id });
      }
      onOpen?.(e.currentTarget);
    },
    { stopPropagation: true },
  );

  const closeKebab = useCallback(() => {
    onClose?.();
    closeMenu();
  }, [onClose]);

  const renderItems = useCallback<SelectListChildren<NodonMenuItemProps>>(
    (item, style) => <ListItem key={item.id} item={item} style={style} />,
    [],
  );

  const sx: SxProps<Theme> = useObjectMemo({
    opacity: hidden ? 0 : 1,
    transition: 'opacity 0.3s',
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      m="6px 8px"
      width={20}
      sx={sx}
      {...boxProps}
    >
      <Tooltip title={tooltip}>
        <Box>
          <KebabButton
            {...buttonProps}
            disabled={items.length === 0}
            onClick={openKebab}
          />
        </Box>
      </Tooltip>
      <SelectMenu
        anchor={anchor}
        anchorPosition={position}
        items={items}
        width={menuWidth}
        onClose={closeKebab}
      >
        {renderItems}
      </SelectMenu>
    </Box>
  );
};

const listItemIconStyles: SxProps = {
  paddingRight: 2,
  minWidth: 0,
} as const;

const arrowIconStyles: SxProps = {
  color: NodonTheme.palette.neutral.light,
} as const;

interface ListItemProps {
  item: NodonMenuItemProps;
  style?: CSSProperties;
}

const ListItem = ({ item, style }: ListItemProps) => {
  const { onClick, tooltip, disabled } = item;

  const handleClick = useCallback(async () => {
    await onClick?.();
    closeMenu();
  }, [onClick]);

  return (
    <SelectMenuNestedItem
      style={style}
      item={item}
      tooltip={tooltip}
      disabled={disabled}
      onClick={handleClick}
    >
      {({ icon, label, checked, checkboxColor, items }) => {
        return (
          <>
            <ListItemIcon sx={listItemIconStyles}>
              {typeof checked === 'boolean' ? (
                <CheckboxButton
                  checked={checked}
                  checkboxColor={checkboxColor}
                />
              ) : (
                icon
              )}
            </ListItemIcon>

            <EllipsisText>{label}</EllipsisText>

            {!!items?.length && <KeyboardArrowRight sx={arrowIconStyles} />}
          </>
        );
      }}
    </SelectMenuNestedItem>
  );
};

interface CheckboxButtonProps {
  checked: boolean;
  checkboxColor?: string;
}

const CheckboxButton = ({ checked, checkboxColor }: CheckboxButtonProps) => {
  const sx = useMemo<SxProps | undefined>(
    () => ({
      '& .MuiSvgIcon-root': {
        fill: checkboxColor,
      },
      padding: 0,
    }),
    [checkboxColor],
  );

  return <Checkbox checked={checked} sx={sx} />;
};
