import {
  Results,
  ConversionFactors,
  PartialConversionFactorQuantityRecord,
  QuantityUnit,
  co2UnitsA,
  co2UnitsB,
  unitsA,
  unitsB,
  ConversionFactorGroupKey,
  co2Units,
  costUnits,
  costUnitsA,
  costUnitsB,
  ProductOrConversionFactors,
  conversionFactorGroupKeys,
} from '../models/unit.interface';
import {
  omit,
  pick,
  getCommonKeys,
  getKeys,
  omitUndefined,
  omitValues,
} from './object_helpers';
import { ArrayOrRecord } from '../models/type_helpers.interface';
import { isDefined } from './array_helpers';
import { IProduct } from '../models/product.interface';
import {
  canConvertConversionFactors,
  ConversionUnits,
  convert,
  convertConversionFactors,
  getConvertableUnitFromConversionFactors,
  getPreferredConversionUnit,
} from './conversion_helpers';
import { required } from './function_helpers';
import { getMean, sum, toNumber } from './math_helpers';
import { uniq } from 'lodash';
import { isQuantityUnit } from './unit_helpers';
import {
  getConversionFactors,
  getProductTransportValue,
  getProductWasteFactor,
} from './results.helpers';

export type SupportedConversionUnits =
  | QuantityUnit
  | ConversionFactorGroupKey
  | ConversionUnits
  | 'co2e_transport'
  | 'co2e_waste';

/**
 * Get a conversion factor value OR a sum of multiple conversion factor values
 * @param factors
 * @param units
 * @returns
 */
export const getConversionFactorValue = (
  productOrConversionFactors?: Results | ProductOrConversionFactors,
  ...units: SupportedConversionUnits[]
): number => {
  const factors = getConversionFactors(productOrConversionFactors);
  const groupKeys = units.filter(isConversionFactorGroupKey);
  const otherKeys: Exclude<
    SupportedConversionUnits,
    ConversionFactorGroupKey
  >[] = units.filter((u) => !isConversionFactorGroupKey(u));
  const keys = [
    ...getConversionFactorKeysFromGroups(...groupKeys),
    ...otherKeys,
  ];
  const values = uniq(keys).map((k) => {
    // If unit exist in factors, return the value
    if (hasConversionFactor(factors, k)) {
      return factors[k as keyof typeof factors];
    }

    if (k === 'co2e_transport') {
      return getProductTransportValue(factors);
    }
    if (k === 'co2e_waste') {
      return getProductWasteFactor(factors);
    }

    // if a unit like mm is requested we need to convert from m
    const fromUnit = getConvertableUnitFromConversionFactors(factors, k);
    if (fromUnit && factors[fromUnit]) {
      return convert(factors[fromUnit], fromUnit, k);
    }
    return 0;
  });
  return sum(values);
};

/**
 * Check if conversion factors have a undefined value for a given unit
 * @param factors
 * @param unit
 * @returns
 */
export const hasConversionFactor = (
  factors: ConversionFactors,
  unit: unknown,
): boolean => isQuantityUnit(unit) && typeof factors[unit] === 'number';

export const createConversionFactors = (
  defaults: Partial<ConversionFactors> | number = 0,
): Results => {
  return compressConversionFactors({
    ...(typeof defaults === 'number' ? { 'co2e_A1-A3': defaults } : defaults),
  });
};

/**
 * Remove any keys that have a value of 0 or undefined.
 * Will not modify the original object if nothing is removed.
 * @param factors
 */
export const compressConversionFactors = <
  T extends Partial<ConversionFactors> | Results,
>(
  factors: T,
): T => {
  return omitValues(factors, 0, undefined, null) as T;
};

const isConversionFactorGroupKey = (
  key: unknown,
): key is ConversionFactorGroupKey =>
  conversionFactorGroupKeys.includes(key as ConversionFactorGroupKey);

/**
 * If we quickly want to get a set of conversion factors, we can call for groups of keys
 * @param groups One or more groups of keys
 * @returns
 */
export const getConversionFactorKeysFromGroups = (
  ...groups: (ConversionFactorGroupKey | QuantityUnit)[]
): QuantityUnit[] => {
  return uniq(
    groups.flatMap((group) => {
      switch (group) {
        case 'A':
          return unitsA;
        case 'B':
          return unitsB;
        case 'co2e':
          return co2Units;
        case 'sek':
          return costUnits;
        case 'co2e_A':
          return co2UnitsA;
        case 'co2e_B':
          return co2UnitsB;
        case 'sek_A':
          return costUnitsA;
        case 'sek_B':
          return costUnitsB;
        default:
          return isQuantityUnit(group) ? [group] : [];
      }
    }),
  );
};

/**
 * Remove properties from conversion factors. Can also remove entire lifecycle phases (A or B).
 * Will not modify the original object if nothing is removed.
 * @param factors
 * @param keys
 * @returns
 */
export const removeConversionFactorsByKey = (
  factors: Partial<ConversionFactors>,
  ...keys: (ConversionFactorGroupKey | QuantityUnit)[]
): Partial<ConversionFactors> => {
  const keysToClear = getConversionFactorKeysFromGroups(...keys);
  return compressConversionFactors(omit(factors, ...keysToClear));
};

/**
 * Merge conversion factors, scaling the factors to a common unit if possible.
 * @param base Default values that can be overriden. Will be scaled to match the overrides unit.
 * @param overrides Will override base values. Note that base values will scale to match the overrides unit.
 * @returns
 */
export const mergeConversionFactors = (
  base: ConversionFactors,
  overrides: Partial<ConversionFactors>,
): ConversionFactors => {
  overrides = compressConversionFactors(overrides);
  return createConversionFactors({
    ...convertConversionFactors(base, overrides),
    ...overrides,
  });
};

/**
 * Get the fallback conversion factors to use if the user input is not complete.
 * @param genericFactors
 * @param userInputFactors
 * @param selectedUnit
 * @returns
 */
export const getFallbackConversionFactors = (
  genericFactors: ConversionFactors | undefined,
  userInputFactors: ConversionFactors,
  selectedUnit: QuantityUnit,
): ConversionFactors => {
  const fallback = omitUndefined(genericFactors ?? {});
  const converted = canConvertConversionFactors(fallback, selectedUnit)
    ? convertConversionFactors(fallback, selectedUnit)
    : fallback;

  return converted;
};

/**
 * Get summed conversion factors from a record of ConversionFactors
 * @param record
 * @returns
 */
export const sumConversionFactorRecord = (
  record: ArrayOrRecord<ConversionFactors>,
): ReturnType<typeof sumConversionFactors> =>
  sumConversionFactors(...Object.values(record));

/**
 * Sum all CO2e factors and return the total.
 * @param factors
 * @returns
 */
export const sumConversionFactors = <T extends ConversionFactors | Results>(
  ...factors: (T | undefined)[]
): T => {
  const conversionFactors = {} as T;
  factors.filter(isDefined).forEach((f) => {
    getKeys(f).forEach((key) => {
      conversionFactors[key] = (toNumber(conversionFactors[key], 0) +
        toNumber(f[key], 0)) as T[keyof T];
    });
  });
  return conversionFactors;
};

export const getEPDConversionFactor = (
  { id, generic_id }: IProduct,
  factors: PartialConversionFactorQuantityRecord,
  key: SupportedConversionUnits,
): number => {
  const epdFactors = factors[id];
  const fallbacks = generic_id ? factors[generic_id] : undefined;

  return (
    getConversionFactorValue(epdFactors, key) ||
    getConversionFactorValue(fallbacks, key)
  );
};

export const getConversionFactorsMean = (
  ...factors: ConversionFactors[]
): ConversionFactors => {
  const conversionUnit = required(
    getPreferredConversionUnit(...factors),
    "Can't get average of conversion factors with no common keys",
  );
  const commonKeys = getCommonKeys(...factors);

  // Convert to the common unit and only keep the common keys to not give faulty means
  const converted = factors.map((f) =>
    pick(convertConversionFactors(f, conversionUnit), ...commonKeys),
  );

  return getMean(...converted);
};
