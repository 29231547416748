import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Box, OutlinedTextFieldProps } from '@mui/material';
import { Row } from '../../Row';
import { RowCell } from '../../RowCell';
import {
  ROOT_CELL_WIDTH,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  ELEMENT_LIST_ITEM_HEIGHT,
  ROOT_CELL_WIDTH_RESPONSIVE,
} from '../../list.constants';
import { useBooleanState } from '../../../../hooks/hooks';
import { useTriggerContextMenu } from '../../../../hooks/menu.hooks';
import { useListRowStyles } from '../../list.style';
import { useMouseEventCallback } from '../../../../hooks/events.hook';
import InlineTextField from '../../../InlineTextField';
import {
  useProjectCostIsDisabled,
  useUpdateElements,
} from '../../../../store/project';
import {
  formatThousands,
  formatValue,
} from '../../../../../../shared/helpers/math_helpers';
import { IBuildingVersion } from '../../../../../../shared/models/project.interface';
import { useNavigateTo } from '../../../../hooks/router.hooks';
import {
  useGetResultsPerGFA,
  useProjectVersionsMax,
  useVersionResultRecord,
} from '../../../../hooks/results.hook';
import {
  getActiveProposal,
  useGetProposalColor,
} from '../../../../hooks/proposals.hook';
import MultiLineTooltip from '../../../MultiLineTooltip';
import ActiveVersionToggle from './ActiveVersionToggle';
import { useSelectedVersionId } from '../../../../store/ui';
import VersionActions from './VersionActions';
import ResultsBarCharts from '../../../charts/ResultsBarCharts';
import { getConversionFactorValue } from '../../../../../../shared/helpers/conversion-factors.helpers';
import {
  getCo2eChartDataFromResults,
  getCostChartDataFromResults,
} from '../../../charts/bar-chart.helpers';

interface VersionListItemProps {
  version: IBuildingVersion;
  autoFocus?: boolean;
  closeMenu: () => void;
}

const VersionListItem: React.FC<VersionListItemProps> = ({
  version,
  autoFocus,
  closeMenu,
}) => {
  const { id } = version;
  const { classes: listClasses } = useListRowStyles();
  const disableCost = useProjectCostIsDisabled();

  const updateElements = useUpdateElements();
  const triggerContextMenu = useTriggerContextMenu(id);
  const navigate = useNavigateTo();
  const getProposalColor = useGetProposalColor();
  const getResultsPerGFA = useGetResultsPerGFA();

  const selectedVersionId = useSelectedVersionId();

  const results = useVersionResultRecord(version)[version.id];
  const resultsPerGFA = getResultsPerGFA(results);

  const co2ePerGfaMax = getResultsPerGFA(useProjectVersionsMax('co2e'));
  const costPerGfaMax = getResultsPerGFA(useProjectVersionsMax('sek'));

  const co2ePerGFA = getConversionFactorValue(resultsPerGFA, 'co2e');
  const costPerGFA = getConversionFactorValue(resultsPerGFA, 'sek');

  const co2ePerGFAData = useMemo(
    () => getCo2eChartDataFromResults(resultsPerGFA),
    [resultsPerGFA],
  );
  const costPerGFAData = useMemo(
    () => getCostChartDataFromResults(resultsPerGFA),
    [resultsPerGFA],
  );

  const [hover, startHover, endHover] = useBooleanState();

  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);

  const proposalColor = useMemo(() => {
    const activeProposal = getActiveProposal();

    if (activeProposal && activeProposal.resultsRecord?.[version.id]) {
      return getProposalColor(activeProposal);
    }
  }, [getProposalColor, version.id]);

  const saveName = useCallback(
    async (name: string) => {
      await updateElements({ id: version.id, name });
      stopEditingName();
    },
    [stopEditingName, updateElements, version],
  );

  const editName = useCallback(() => {
    startEditingName();
    endHover();
  }, [endHover, startEditingName]);

  const cancelEdit = useCallback(() => {
    setTimeout(() => stopEditingName(), 250);
  }, [stopEditingName]);

  const handleRowClick = useMouseEventCallback(() => {
    if (!isEditingName) {
      navigate({ versionId: version.id });
      closeMenu();
    }
  });

  // Start editing the name if autoFocus is set
  useEffect(() => {
    autoFocus && startEditingName();
  }, [autoFocus, startEditingName]);

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        e.currentTarget.select();
      },
    }),
    [autoFocus],
  );

  return (
    <Box
      width="100%"
      component="div"
      onMouseLeave={endHover}
      onMouseOver={startHover}
      onClick={handleRowClick}
    >
      <Row
        padding
        classes={listClasses}
        height={ELEMENT_LIST_ITEM_HEIGHT}
        onContextMenu={triggerContextMenu}
        hover={hover}
        spacing={LIST_SPACING.DEFAULT}
      >
        {/* CONTENT. Group content in a shared cell to make sure we can align bar charts as one */}
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT * 0.8}
          paddingRight={LIST_SPACING}
        >
          <Row height={ELEMENT_LIST_ITEM_HEIGHT} spacing={LIST_SPACING.DEFAULT}>
            {/* Check icon */}
            <RowCell width={ROOT_CELL_WIDTH.ICON} align="center">
              <ActiveVersionToggle versionId={version.id} hover={hover} />
            </RowCell>

            {/* Name input */}
            <RowCell
              width={CONTENT_CELL_WIDTH.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
            >
              <InlineTextField
                autoWidth
                variant="subtitle1"
                value={version.name}
                editing={isEditingName}
                readonly={!isEditingName}
                inactive={version.id !== selectedVersionId}
                textFieldProps={nameInputProps}
                onSave={saveName}
                onCancel={cancelEdit}
              />
            </RowCell>

            {/* Actions icon */}
            <RowCell width={CONTENT_CELL_WIDTH.ICON} hideOnPrint align="center">
              <VersionActions
                versionId={version.id}
                hover={hover}
                isEditingName={isEditingName}
                onEdit={editName}
              />
            </RowCell>
          </Row>
        </RowCell>

        {/* Bar chart */}
        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <ResultsBarCharts
            co2e={co2ePerGFAData}
            co2eMax={co2ePerGfaMax}
            cost={costPerGFAData}
            costMax={costPerGfaMax}
            color={proposalColor}
            disableCost={disableCost}
          />
        </RowCell>

        {/* CO2 Label */}
        <RowCell
          width={CONTENT_CELL_WIDTH.LABEL}
          align="center"
          fontSize={12}
          fontWeight={700}
        >
          <MultiLineTooltip
            rows={['Emissions per GFA', 'kgCO2e / m2']}
            disableInteractive
          >
            {formatThousands(co2ePerGFA)}
          </MultiLineTooltip>
        </RowCell>

        {/* Cost Label */}
        <RowCell
          width={CONTENT_CELL_WIDTH.LABEL}
          align="center"
          fontSize={10}
          fontWeight={400}
        >
          <MultiLineTooltip
            rows={['Cost per GFA', 'kSEK / m2']}
            disableInteractive
          >
            {formatValue(costPerGFA, 'k')}
          </MultiLineTooltip>
        </RowCell>
      </Row>
    </Box>
  );
};

export default memo(VersionListItem);
