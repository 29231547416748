import { Box, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import NodonSelect from '../../../NodonSelect';
import { useElementResults } from '../../../../hooks/results.hook';
import { formatValue } from '../../../../../../shared/helpers/math_helpers';
import { SelectableQuantityUnit } from '../../../../../../shared/models/unit.interface';
import { useProjectBuildingGFA } from '../../../../store/project';
import { NodonTheme } from '../../../../style';

import { isSelectableQuantityUnit } from '../../../../../../shared/helpers/unit_helpers';
import { uniq } from 'lodash';
import { createLocalStorageStore } from '../../../../helpers/local-storage.helpers';
import { IElement } from '../../../../../../shared/models/project.interface';
import { isSystemCategory } from '../../../../../../shared/helpers/element_category_helpers';
import {
  getConversionFactorValue,
  SupportedConversionUnits,
} from '../../../../../../shared/helpers/conversion-factors.helpers';

type CostEmissionFactor = Extract<SupportedConversionUnits, 'co2e' | 'sek'>;
type FactorUnit = Exclude<SelectableQuantityUnit, 'l' | 'mm'> | 'total' | 'GFA';
type FactorUnitRecord = Record<CostEmissionFactor, FactorUnit[]>;

const defaultFactorUnits: FactorUnitRecord = {
  co2e: ['total'],
  sek: ['total'],
};

// Todo use record store
const { useStore: useConversionFactorState, set: setPreferredFactorUnits } =
  createLocalStorageStore<FactorUnitRecord>(
    'preferred_product_element_emission_units',
    defaultFactorUnits,
  );

interface EmissionsProps {
  factor: CostEmissionFactor;
  element: IElement;
}

const ResultPanel: React.FC<EmissionsProps> = ({
  factor,
  element: { id, category_id },
}) => {
  const gfa = useProjectBuildingGFA();
  const results = useElementResults(id);

  const selectableUnits: FactorUnit[] = useMemo(() => {
    const defaultUnits: FactorUnit[] = ['total', 'GFA'];

    if (isSystemCategory(category_id)) {
      return defaultUnits;
    }
    return [...defaultUnits, ...Object.keys(results).filter(isFactorUnit)];
  }, [results, category_id]);

  const preferredFactorUnits = useConversionFactorState();

  const selectedUnit = useMemo(() => {
    const factorUnits = preferredFactorUnits?.[factor]
      ? preferredFactorUnits[factor]
      : defaultFactorUnits[factor];

    return (
      factorUnits.find((unit) => selectableUnits.includes(unit)) ?? 'total'
    );
  }, [factor, preferredFactorUnits, selectableUnits]);

  const selectedFactorValue = useMemo(() => {
    return formatValue(getConversionFactorValue(results, factor), factor, {
      perUnit:
        selectedUnit !== 'total'
          ? {
              unit: selectedUnit,
              value: selectedUnit !== 'GFA' ? results[selectedUnit] : gfa,
            }
          : undefined,
    });
  }, [results, gfa, selectedUnit, factor]);

  const handleChange = useCallback(
    (unit: string) => {
      if (!isFactorUnit(unit)) {
        throw new Error(`Invalid unit: ${unit}`);
      }

      const factorUnits = preferredFactorUnits?.[factor]
        ? preferredFactorUnits
        : defaultFactorUnits;

      setPreferredFactorUnits({
        ...factorUnits,
        [factor]: uniq([unit, ...factorUnits[factor]]),
      });
    },
    [factor, preferredFactorUnits],
  );

  const options = useMemo(
    () => selectableUnits.map((u) => ({ value: u, label: getUnitPrefix(u) })),
    [selectableUnits],
  );

  return (
    <Box
      pt={1}
      pb={2}
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <NodonSelect
        buttonLabel={getButtonLabel(factor, selectedUnit)}
        options={options}
        onChange={handleChange}
        buttonStyles={{ color: NodonTheme.palette.text.primary }}
      />
      <Typography variant="body2">{selectedFactorValue}</Typography>
    </Box>
  );
};

const isFactorUnit = (unit: unknown): unit is FactorUnit =>
  typeof unit === 'string' &&
  !['mm', 'l'].includes(unit) && // Don't show these units
  (isSelectableQuantityUnit(unit) || ['total', 'GFA'].includes(unit)); // Selectable units + total and GFA

const getUnitPrefix = (unit: FactorUnit) => {
  if (unit === 'total') {
    return 'Total';
  }
  return `Per ${unit}`;
};

const getButtonLabel = (factor: CostEmissionFactor, unit: FactorUnit) => {
  let factorLabel;
  if (factor === 'co2e') factorLabel = 'Emissions';
  if (factor === 'sek') factorLabel = 'Cost';

  return `${factorLabel ?? 'unkown factor'} ${getUnitPrefix(unit)}`;
};

export default ResultPanel;
