import React, { useMemo } from 'react';
import { Results } from '../../../../../../shared/models/unit.interface';
import {
  useProjectListMaxResults,
  useProjectListGFAScale,
  useProjectCostIsDisabled,
} from '../../../../store/project';
import ResultsBarCharts from '../../../charts/ResultsBarCharts';
import { PROJECT_CO2_BAR_HEIGHT } from '../../list.constants';
import { useGetResultsPerGFA } from '../../../../hooks/results.hook';
import { getConversionFactorValue } from '../../../../../../shared/helpers/conversion-factors.helpers';
import { ResultsTooltip } from '../../../ResultsTooltip';
import {
  getCo2eChartDataFromResults,
  getCostChartDataFromResults,
} from '../../../charts/bar-chart.helpers';

interface ProjectListItemBarChartsProps {
  gfa: number;
  results?: Results;
}

const ProjectListItemBarCharts: React.FC<ProjectListItemBarChartsProps> = ({
  gfa,
  results,
}) => {
  const getResultsPerGFA = useGetResultsPerGFA();
  const maxResults = useProjectListMaxResults();
  const gfaScale = useProjectListGFAScale(gfa);
  const disableCost = useProjectCostIsDisabled();
  const resultsPerGFA = getResultsPerGFA(results, gfa);
  const co2ePerGfaMax = getConversionFactorValue(maxResults, 'co2e');
  const costPerGfaMax = getConversionFactorValue(maxResults, 'sek');

  const co2ePerGFAData = useMemo(
    () => getCo2eChartDataFromResults(resultsPerGFA),
    [resultsPerGFA],
  );
  const costPerGFAData = useMemo(
    () => getCostChartDataFromResults(resultsPerGFA),
    [resultsPerGFA],
  );

  return (
    <ResultsTooltip results={resultsPerGFA} perGFA={true}>
      <ResultsBarCharts
        pl={2}
        disableCost={disableCost}
        co2e={co2ePerGFAData}
        co2eMax={co2ePerGfaMax}
        cost={costPerGFAData}
        costMax={costPerGfaMax}
        co2eHeight={gfaScale * PROJECT_CO2_BAR_HEIGHT}
      />
    </ResultsTooltip>
  );
};

export default ProjectListItemBarCharts;
