import { QuantityUnit } from '../models/unit.interface';
import {
  ExpressionValue,
  IBuildingVersion,
  OneOfElements,
  IElementID,
  ICountAndUnit,
  Project,
  IProductElement,
  ElementKind,
} from '../models/project.interface';
import { isNumeric, multiply, removeNumberFormatting } from './math_helpers';
import {
  flattenElements,
  getPathToElement,
  isProductElement,
} from './recursive_element_helpers';
import { replaceBuildingVersion, updateElements } from './project_helpers';
import { pick } from './object_helpers';
import { mathJS, removeVariablePrefixes } from './mathjs';
import {
  getElementAndQuantityProperties,
  isElementExpressionProperty,
  updateElementPropertiesFromVariablesRecord,
} from './element_property_helpers';
import {
  createEmptyExpression,
  createExpression,
  isExpressionValue,
} from './expression_factory_helpers';
import {
  getExpressionVariablesRecord,
  ExpressionVariables,
  ExpressionVariablesRecord,
  defaultVariables,
} from './expression_variables_helpers';
import { isEqual, isError, isObject } from 'lodash';
import { isDefined } from './array_helpers';
import { cacheFactory } from './function_helpers';
import { IElementProperty } from '../models/element_property.interface';
import { concreteDensity } from '../templates/categories/concrete/concrete';
import { replaceCharAt } from './string_helpers';
import { requiredKind } from './element_helpers';

export enum ExpressionErrorMessage {
  ForbiddenSymbol = 'Forbidden symbol',
  UndefinedProperty = 'Property not defined',
  UnexpectedEnd = 'Unexpected end of expression',
  NotFinite = 'Not a finite number',
  NotString = 'Expression not a string',
  UnknowError = 'Could not evaluate expression',
  MissingEvaluate = 'Missing evaluate function on expression solver.',
  Circular = 'Circular reference found',
  UnhandledCircular = 'Probable circular reference, break to not create infinite loop',
}

export type ResolvedCountRecord = Record<
  IElementID,
  { count: number; unit: QuantityUnit }
>;

interface ISolveOptions {
  min?: number;
  max?: number;
}

export const VARIABLE_NAMES_WITH_PERCENTAGES: Record<string, boolean> = {
  fill: true,
  reused_content: true,
  ratio: true,
};

const getPropNamesWithPercentageUnits = (properties: IElementProperty[]) =>
  properties
    .filter((p) => isElementExpressionProperty(p) && p.unit === '%')
    .reduce((acc, { name }) => ({ ...acc, [name]: true }), {});

export const getElementPropertyVariableNamesWithPercentages = (
  element: OneOfElements,
  version?: IBuildingVersion,
): Record<string, boolean> => {
  return cacheFactory(
    () => {
      // If element is not added to a version use empty path
      const path =
        (!!version && getPathToElement(version, element, false)) || [];

      const properties = [...path, element]
        .map(getElementAndQuantityProperties)
        .flat();

      return getPropNamesWithPercentageUnits(properties);
    },
    `getElementPropertyVariableNamesWithPercentages[${element.id}]`,
    [element],
  );
};

/**
 * Main pipeline of calculation. Update all ElementProperties and then all elements
 * Resolve all formulas in a version to update all count values to latest value.
 * @param project
 * @returns
 */
export const updateVersionExpressionValues = (
  project: Project,
  version: IBuildingVersion,
): Project => {
  // 1. Get all up-to-date variables available for each element (values only)
  const record = getExpressionVariablesRecord(project, version);

  // 2. Update all properties with the freshly calculated variables
  version = updateElementPropertiesFromVariablesRecord(version, record);

  // 3. Update all "count" values in this version.
  version = resolveCountExpressions(version, record);

  // 4. Update apply version changes to project
  return replaceBuildingVersion(project, version);
};

/**
 * Combine two expressions into one.
 * Note that variables are not supported currently.
 * @param a
 * @param b
 * @returns
 */
export const addExpressions = (
  a: ExpressionValue,
  b: ExpressionValue,
): ExpressionValue => {
  let new_exp = '';
  if (a.expression.length > 0 && b.expression.length > 0) {
    new_exp = a.expression + '+' + b.expression;
  } else if (a.expression.length > 0) {
    new_exp = a.expression;
  } else if (b.expression.length > 0) {
    new_exp = b.expression;
  }

  try {
    const solved = solve(new_exp);
    return solved;
  } catch (error) {
    throw new Error(
      'Could not solve expression when adding: "' +
        new_exp +
        '".\n' +
        String(error),
    );
  }
};

/**
 * Solve formulas but return an expression even if unsuccessful.
 * Note that it only returns a new expression if changed
 * @param expression
 * @param variables
 * @returns
 */
export const errorHandledSolve = (
  expression: ExpressionValue,
  variables?: ExpressionVariables,
  options?: ISolveOptions,
): ExpressionValue => {
  try {
    const newExp = solve(expression.expression, variables, options);
    return isEqual(newExp, expression) ? expression : newExp;
  } catch (e) {
    handleExpressionError(e);
    return createExpression(expression.expression);
  }
};

const evaluateExpression = (
  expression: string,
  variables?: ExpressionVariables,
): number => {
  if (!mathJS.evaluate) {
    throw new Error(ExpressionErrorMessage.MissingEvaluate);
  }

  return mathJS.evaluate(expression, variables ?? {}) as number;
};

/**
 * Resolve an expression
 * @param expression Math expression like "a * 12 / storeys_count"
 * @param variables A record of available variables: { a: 0, b: 1}
 * @param options Options for min and max values etc
 * @returns A resolved expression containing the resulting numeric value
 */
export const solve = (
  expression: string,
  variables?: ExpressionVariables,
  options: ISolveOptions = {},
): ExpressionValue => {
  if (typeof expression !== 'string') {
    throw new Error(ExpressionErrorMessage.NotString);
  }
  // Allow "==", "==", "!=", "<=" or ">=" but not a single "=" or "==="
  if (/(^|[^!<>=])(=|={3,})([^=]|$)/gi.test(expression)) {
    throw new Error(ExpressionErrorMessage.ForbiddenSymbol);
  }
  if (expression.length === 0) {
    return createEmptyExpression();
  }

  // TODO: refactor this crutch. DEC-1517: fallback for density on concrete PC that is type != cast-in-place causes error

  const expressonPlus =
    expression === concreteDensity && !variables?.reinforcement_steel
      ? 'densityArray[1]'
      : expression;

  const res = evaluateExpression(
    removeVariablePrefixes(expressonPlus),
    variables,
  );

  if (typeof res === 'number') {
    const { min, max } = options;

    // Throw error for NaN, -Inifinite and Infinite
    if (!isFinite(res)) {
      throw new Error(ExpressionErrorMessage.NotFinite);
    }

    // Throw error if min or max is violated
    if (typeof min === 'number' && res < min) {
      throw new Error(`Value must be greater than or equal to ${min}`);
    }
    if (typeof max === 'number') {
      if (res > max) {
        throw new Error(`Value must be less than or equal to ${max}`);
      }

      /* 
      Special case for percentage (should maybe add a percentage flag to options object 
      instead of checking if max is 100) 
      */
      if (max === 100 && res * 100 > max) {
        throw new Error(`Value must be less than or equal to ${max}%`);
      }
    }

    return createExpression({ resolved: res, expression: expressonPlus });
  }
  // Something unexpected happened. Seem to happen with the variable names "i", "e"
  throw new Error(ExpressionErrorMessage.UnknowError);
};

enum NumberError {
  Dot = 'Unexpected part ".',
  Comma = 'Unexpected operator ,',
  Space = 'Unexpected part',
  Parenthesis = 'Parenthesis ) expected',
  Arguments = 'Too many arguments in function',
}

const getFormatError = (error: unknown): NumberError | undefined => {
  if (isError(error) && typeof error.message === 'string') {
    const msg = error.message;
    if (msg.startsWith(NumberError.Comma)) {
      return NumberError.Comma;
    }
    // TODO: handle this error
    // if (msg.startsWith(NumberError.Dot)) {
    //   return NumberError.Dot;
    // }
    if (msg.startsWith(NumberError.Space)) {
      return NumberError.Space;
    }
    if (msg.startsWith(NumberError.Parenthesis)) {
      return NumberError.Parenthesis;
    }
    if (msg.startsWith(NumberError.Arguments)) {
      return NumberError.Arguments;
    }
  }
};

/**
 * Resolve an expression and if use have inputed other number formats than
 * supported by mathjs, it will try to fix it. (1,32 => 1.32, 1 999 => 1999)
 * @param expression Math expression like "a * 12 / storeys_count"
 * @param variables A record of available variables: { a: 0, b: 1}
 * @param options Options for min and max values etc
 * @param breakOnError Pass true to break on error to avoid infinite loops
 * @returns A resolved expression containing the resulting numeric value
 */
export const fixNumberFormatSolve = (
  expression: string,
  variables?: ExpressionVariables,
  options: ISolveOptions = {},
  breakOnError?: NumberError,
): ExpressionValue => {
  // Short to rerun the function
  const rerun = (exp: string, breakOnError?: NumberError) =>
    fixNumberFormatSolve(exp, variables, options, breakOnError);

  try {
    return solve(expression, variables, options);
  } catch (error) {
    const errorType = getFormatError(error);

    // Try to fix the error if it possible to handle and has not happened before
    if (isError(error) && errorType && errorType !== breakOnError) {
      const { message } = error;
      const char = 'char' in error ? error.char : undefined;
      const data: { fn: string } = (error as any).data;
      const fn =
        isObject(data) && typeof data.fn === 'string' ? data.fn : undefined;

      // Turn 1,2 + 2 -> 1.2 + 2
      if (
        typeof char === 'number' &&
        typeof message === 'string' &&
        errorType === NumberError.Comma
      ) {
        const removedFormatting = removeNumberFormatting(expression);

        // If the whole expression is a number it's easier to resolve
        if (isNumeric(removedFormatting)) {
          return rerun(removedFormatting);
        }

        // Replace the comma with a dot and try again
        return rerun(replaceCharAt(expression, char - 1, '.'));
      }

      // Turn "1 999 + 23" -> "1999 + 23"
      if (errorType === NumberError.Space) {
        return rerun(
          expression.replace(/(?<=\d)\s+(?=\d)/g, ''),
          NumberError.Space,
        );
      }

      if (errorType === NumberError.Parenthesis) {
        // Get anything inside a parenthesis
        return rerun(
          expression.replace(
            /(?<=\()[^)]+(?=\))/,
            removeSpaceAndCommaBetweenNumbers,
          ),
          NumberError.Parenthesis,
        );
      }
      if (errorType === NumberError.Arguments && fn) {
        return rerun(
          expression.replace(
            new RegExp(`(?<=${fn}\\()[^)]+(?=\\))`, 'g'),
            removeSpaceAndCommaBetweenNumbers,
          ),
          NumberError.Arguments,
        );
      }
    }

    throw error;
  }
};

const removeSpaceAndCommaBetweenNumbers = (expression: string): string =>
  expression
    .replace(/(?<=\d)\s+(?=\d)/g, '') // Remove spaces between numbers
    .replace(/(?<=\d),(?=\d)/g, '.'); // Replace comma between numbers with dot

/**
 * Update all count expressions in one or multiple versions
 * @param root
 * @param variablesRecord A record of availale variables for each element
 * @returns A modified project
 */
export const resolveCountExpressions = <R extends IBuildingVersion | Project>(
  root: R,
  variablesRecord: ExpressionVariablesRecord,
): R => {
  const updatedElements = flattenElements(root)
    .filter(isProductElement)
    .filter((element) => variablesRecord[element.id]) // Allow to pass a more slimmed down record to improve performance
    .map((element) => resolveCountExpressionInElement(element, variablesRecord))
    .filter(isDefined);

  return updatedElements.length
    ? updateElements(
        root,
        // Only update count (to not overwrite child elements)
        ...updatedElements.map((e) => pick(e, 'id', 'count')),
      )
    : root;
};

/**
 * Update count expression in Element regardless of type.
 * Return undefined if count is not changed.
 * @param element
 * @param variablesRecord
 * @returns A list of updated properties
 */
const resolveCountExpressionInElement = <T extends IProductElement>(
  element: T,
  variablesRecord: ExpressionVariablesRecord,
): Pick<T, 'kind' | 'id' | 'count' | 'unit'> | undefined => {
  const variables = getVariablesById(variablesRecord, element.id);
  const base = pick(
    requiredKind(element, ElementKind.Product),
    'kind',
    'id',
    'unit',
  );

  const count = getResolvedCountExpression(element, variables);

  return element.count !== count
    ? {
        ...base,
        count,
      }
    : undefined;
};

/**
 * Get resolved expression from product
 * @param countAndUnit
 * @param variables
 * @returns
 */
const getResolvedCountExpression = (
  countAndUnit: ICountAndUnit | undefined,
  variables: ExpressionVariables,
  options?: ISolveOptions,
): ExpressionValue => {
  // Safely handle old projects without crashing.
  return typeof countAndUnit?.count !== 'object'
    ? createExpression(0)
    : errorHandledSolve(countAndUnit.count, variables, options);
};

/**
 * Get an existing expression or create a new one from a number or string
 * @param value
 * @returns
 */
export const getExpression = (
  value: number | ExpressionValue | Partial<ExpressionValue> | string = 0,
): ExpressionValue => {
  // Expression already exist so use that
  if (isExpressionValue(value)) {
    return value;
  }

  return createExpression(value);
};

export const getVariablesById = (
  record: ExpressionVariablesRecord,
  id?: IElementID,
): ExpressionVariables => (id && record[id]) || { ...defaultVariables };

/**
 * Handle expression parsing errors in a unified way
 * returning the error message if it's a handled error
 * @param error
 * @returns If it's a handled error return the error message
 */
export const handleExpressionError = (error: unknown): string => {
  const message =
    error instanceof Error ? (error.message as ExpressionErrorMessage) : '';

  if (message === ExpressionErrorMessage.MissingEvaluate) {
    throw error;
  }

  if (
    message === ExpressionErrorMessage.ForbiddenSymbol ||
    message === ExpressionErrorMessage.NotFinite ||
    message === ExpressionErrorMessage.UnknowError
  ) {
    return message;
  }

  // A variable is missing
  if (message.includes('Unexpected type of argument')) {
    return ExpressionErrorMessage.UndefinedProperty;
  }

  // Will return "Undefined symbol [property-name]"
  if (message.includes('Undefined symbol')) {
    return message;
  }

  if (message.includes('Unexpected end of expression')) {
    return ExpressionErrorMessage.UnexpectedEnd;
  }

  return ExpressionErrorMessage.UnknowError;
};

/**
 * Get the resolved (numeric) value of a the count property of an element
 */
export const getResolvedExpression = (
  element: Pick<ICountAndUnit, 'count'>,
): number => element.count?.resolved || 0;

/**
 * Test if the ExpressionValue is equal to  another ExpressionValue
 * By default it will only compare the expression and not the resolved value
 * @param a
 * @param b
 * @param compareResolvedValues Pass true to also compare resolved values
 * @returns
 */
export const isEqualExpressionValues = (
  a: ExpressionValue | undefined,
  b: ExpressionValue | undefined,
  compareResolvedValues = false,
): boolean => {
  return (
    a?.expression === b?.expression &&
    (!compareResolvedValues || a?.resolved === b?.resolved)
  );
};

export const formatExpressionErrorMessage = ({ message }: Error): string => {
  if (message.includes('addScalar')) {
    return 'Invalid use of variable';
  }
  if (message.includes('actual: Object')) {
    return 'Expression includes invalid variable';
  }

  const first = message.split(' (char ')[0];
  if (first) return first;

  if (message.includes('Undefined symbol')) {
    const undefinedSymbol = message.slice(message.lastIndexOf(' ') + 1);
    return `"${undefinedSymbol}" doesn't exist`;
  }
  return message;
};

export const divideExpressionValue = (
  expressionValue: ExpressionValue,
  value: number,
): ExpressionValue => {
  if (value === 0) {
    throw new Error('Cannot divide by zero');
  }
  if (value === 1) {
    return expressionValue;
  }
  const { resolved, expression } = expressionValue;

  return createExpression({
    resolved: resolved / value,
    expression: isNumeric(expression)
      ? `${+expression / value}`
      : `(${expression}) / ${value}`,
  });
};

export const multiplyExpressionValues = (
  ...values: (ExpressionValue | number | undefined)[]
): ExpressionValue => {
  const expValues = values
    .filter(isDefined)
    .map((value) => getExpression(value));
  const expression = multiplyExpressions(
    ...expValues.map((value) => value.expression),
  );
  const resolved = multiply(expValues.map((value) => value.resolved));
  return createExpression({ expression, resolved });
};

export const multiplyExpressions = (...expressions: string[]): string => {
  const newExpression = expressions
    .map((expression) => expression.trim())
    .map((expression) =>
      needParenthesesInMultiplication(expression)
        ? `(${expression})`
        : expression,
    )
    .filter((expression) => expression !== '1')
    .join(' * ');

  // Check if expression only contains math operators and numbers
  if (/^[0-9()+\-*/.\s]*$/.test(newExpression)) {
    try {
      const result = mathJS.evaluate(newExpression);
      return isFinite(result) ? String(result) : newExpression;
    } catch {
      // If evaluation fails, return original expression
    }
  }
  return newExpression;
};

export const needParenthesesInMultiplication = (
  expression: string,
): boolean => {
  expression = expression.trim();
  if (isNumeric(expression)) {
    return false;
  }
  // Return false if already wrapped in parentheses or is a function call
  if (expression.startsWith('(') && expression.endsWith(')')) {
    return false;
  }

  // Check if expression is a function call like max(1,2)
  if (/^[a-zA-Z]+\([^)]*\)$/.test(expression)) {
    return false;
  }

  return /[+-]/.test(expression);
};
