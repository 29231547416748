import { isObject, omit } from 'lodash';
import {
  calculatedUnits,
  ConversionFactors,
  Results,
  QuantityUnit,
  EmissionCostLabel,
} from '../models/unit.interface';
import { mathJS } from './mathjs';
import { multiplyProperties } from './math_helpers';
import { getCommonKeys, omitUndefined } from './object_helpers';
import { isCO2eUnit, isCostUnit, isQuantityUnit } from './unit_helpers';
import { isOneOf } from './array_helpers';
import {
  getConversionFactorValue,
  hasConversionFactor,
  SupportedConversionUnits,
} from './conversion-factors.helpers';

const unitRecord = {
  length: [
    'mm',
    'cm',
    'dm',
    'm',
    'km',
    'inch',
    'ft',
    'yd',
    'mi',
    'nmi',
  ] as const,
  area: [
    'cm2',
    'cm²',
    'dm2',
    'm²',
    'km',
    'km2',
    'km²',
    'm2',
    'm²',
    'mm2',
    'mm²',
  ] as const,
  mass: [
    'kg',
    'tonne', // metric ton
    't', // metric ton
    'g',
    'hg',
    'mg',
    'lb', // pound
    'N',
    'kN',
  ] as const,
  volume: ['cm3', 'cm³', 'dm3', 'dm³', 'm3', 'm³', 'mm3', 'mm³', 'l'] as const,
  time: ['s', 'h', 'day', 'year', 'hour', 'min', 'day'] as const,
  energy: ['J', 'kJ', 'MJ', 'kWh'] as const,
  arealDensity: ['kg/m²', 'g/cm²', 'kg/m2', 'g/cm2'] as const,
  density: ['kg/m3', 'g/cm3', 'kg/m²', 'g/cm²'] as const,
  flow: ['l/s', 'm3/s', 'm³/s'] as const,
  arealFlow: ['l/m2s', 'l/m²s', 'm3/m2s', 'm³/m²s'] as const,
};

const conversionUnits = [
  ...unitRecord.length,
  ...unitRecord.area,
  ...unitRecord.volume,
  ...unitRecord.mass,
  ...unitRecord.time,
  ...unitRecord.energy,
  ...unitRecord.arealDensity,
  ...unitRecord.density,
  ...unitRecord.flow,
  ...unitRecord.arealFlow,
] as const;

export type ConversionUnits = (typeof conversionUnits)[number];

const G = 9.81;

if (
  typeof mathJS.unit !== 'function' ||
  typeof mathJS.createUnit !== 'function'
) {
  throw new Error('mathjs dependencies are not installed');
}

mathJS.createUnit('N', `${1 / G} kg`, { override: true });
mathJS.createUnit('kN', `1000 N`);

export const stripCalculatedConversionFactors = (
  conversionFactors: ConversionFactors,
): ConversionFactors => {
  return omit(conversionFactors, calculatedUnits);
};

/**
 * Convert the count to another unit maintaining the same ratio.
 * Note that ConversionFactors now has been migrated to expected format (2022-08-30)
 * Sample: {kg: 1, m³: 0.00142857}
 */
export const convertCount = (
  count: number,
  conversionFactors: ConversionFactors,
  from: QuantityUnit,
  to: SupportedConversionUnits,
): number => {
  if (from === to) {
    return count;
  }
  const fromFactor = getConversionFactorValue(conversionFactors, from);
  const toFactor = getConversionFactorValue(conversionFactors, to);

  if (
    typeof fromFactor !== 'number' ||
    typeof toFactor !== 'number' ||
    toFactor === 0 ||
    fromFactor === 0
  ) {
    return 0;
  }
  return (count * toFactor) / fromFactor;
};

/**
 * Make conversionFactors to relate to the given unit.
 * Provide a count to scale the conversionFactors, if not provided it will be relative to 1 unit of the conversionFactors.
 * If we can't convert to a unit all values will be 0
 * @param factors
 * @param convertTo QuantityUnit to convert to. If passing conversion_factors, it will find a common unit to convert to.
 * @param count
 * @returns
 */
export const convertConversionFactors = <T extends Results | ConversionFactors>(
  factors: T,
  convertTo: QuantityUnit | T,
  count = 1,
): T => {
  if (typeof convertTo !== 'string') {
    if (!isObject(convertTo)) {
      throw new Error('convertTo must be a string or an object');
    }

    // Common unit to used to scale the conversion factors
    const unit = getConvertToUnit(factors, convertTo);

    // Run this method again with a selected unit if found else return empty conversion factors
    return isQuantityUnit(unit)
      ? convertConversionFactors(factors, unit, convertTo[unit])
      : ({} as T);
  }

  const to = factors[convertTo];
  const factor = to ? count / to : 0;
  return multiplyConversionFactors(omitUndefined(factors) as T, factor);
};

/**
 * Get units that we can convert between.
 * I.E the non-CO2e and non-cost units (like kg, m³, kWh, m²)
 * @param factors
 * @returns
 */
export const getConversionUnits = (
  ...factors: ConversionFactors[]
): QuantityUnit[] =>
  getCommonKeys(...factors).filter(
    (key) => !isCO2eUnit(key) && !isCostUnit(key),
  );

const PREFERRED_ORDER: QuantityUnit[] = ['kg', 'm³', 'kWh', 'm²'];

/**
 * Get the preferred conversion unit.
 * An available unit in the conversion factor that is most likely to be used.
 * @param factors
 * @returns
 */
export const getPreferredConversionUnit = (
  ...factors: ConversionFactors[]
): QuantityUnit | undefined => {
  const conversionUnits = getConversionUnits(...factors);
  for (const unit of PREFERRED_ORDER) {
    if (conversionUnits.includes(unit)) {
      return unit;
    }
  }
  return conversionUnits[0];
};

/**
 * Get a unit that is common to both conversion factors. Can be used to scale/align conversion factors.
 * @param from
 * @param to
 * @returns
 */
const getConvertToUnit = (
  from: ConversionFactors,
  to: ConversionFactors,
): QuantityUnit | undefined => {
  // All non-CO2e units in both factors
  const commonKeys = getConversionUnits(from, to);

  return (
    commonKeys.find((key) => to[key] === 1) ?? // Prefer units that are 1 in the convertTo object) ??
    commonKeys.find((key) => from[key] === to[key]) ?? // Prefer units that are the same in both objects
    commonKeys[0] // Default to the first common key
  );
};

/**
 * Test if a conversion factor can be converted to a unit.
 * @param conversionFactors
 * @param unit
 * @returns
 */
export const canConvertConversionFactors = (
  conversionFactors: ConversionFactors,
  unit: QuantityUnit | ConversionFactors,
): boolean => {
  if (typeof unit === 'string') {
    if (!isQuantityUnit(unit)) {
      throw new Error(`Unit ${String(unit)} is not a valid quantity unit`);
    }
    return (
      unit in conversionFactors &&
      typeof conversionFactors[unit] === 'number' &&
      isFinite(conversionFactors[unit])
    );
  }
  return getConvertToUnit(conversionFactors, unit) !== undefined;
};

export const multiplyConversionFactors = <
  T extends Results | ConversionFactors,
>(
  conversionFactors: T | undefined,
  factor: number,
): T => {
  return multiplyProperties(conversionFactors ?? {}, factor) as T;
};

const replaceSuperscriptUnits = (
  unit: ConversionUnits | QuantityUnit,
): ConversionUnits | QuantityUnit => {
  return unit.replace(/²/g, '2').replace(/³/g, '3') as ConversionUnits;
};

export const getConvertableUnitFromConversionFactors = (
  conversionFactors: ConversionFactors,
  to: QuantityUnit | ConversionUnits,
): QuantityUnit | undefined => {
  // If the unit is already in the conversionFactors object, return it
  if (hasConversionFactor(conversionFactors, to)) {
    return to as QuantityUnit;
  }

  // Search for the unit in the unitRecord
  for (const units of Object.values(unitRecord)) {
    // If the unit is a part of a group of units (like mm is part of length units)
    if (isOneOf(units, to)) {
      // Find the unit that is in the conversionFactors object that relates to this unit (if mm we want to find m)
      return units.find((unit) =>
        hasConversionFactor(conversionFactors, unit),
      ) as QuantityUnit;
    }
  }
};

/**
 * Convert a value from one unit to another
 * @param value
 * @param from
 * @param to
 * @returns
 */
export const convert = (
  value: number,
  from: QuantityUnit | ConversionUnits,
  to: QuantityUnit | ConversionUnits,
): number => {
  from = replaceSuperscriptUnits(from);
  to = replaceSuperscriptUnits(to);

  if (from === to) {
    return value;
  }

  const availableUnits = Object.values(unitRecord).find((units) =>
    isOneOf(units, from),
  );

  // Can't convert to a unit that is not available
  if (!isOneOf(availableUnits, to)) {
    throw new Error(`Conversion from ${from} to ${String(to)} is not allowed`);
  }

  return mathJS.unit(value, from).toNumber(to);
};

/**
 * Get densite as kg/m³
 * @param factors
 * @returns
 */
export const getDensity = (factors: ConversionFactors): number | undefined => {
  const { kg, 'm³': m3 } = factors;

  if (typeof kg === 'number' && typeof m3 === 'number') {
    return !m3 ? 0 : kg / m3;
  }
};

/**
 * Determine if cost should be in thousands SEK or not
 * NOTE: formatValue handles this automatically
 */
export const convertToThousandSEK = (
  value: number,
  unitLabel: EmissionCostLabel = 'kSEK',
): number => (unitLabel === 'kSEK' ? value / 1000 : value);
