import React, { useCallback, useMemo } from 'react';
import {
  IBuildingVersion,
  OneOfElements,
} from '../../../shared/models/project.interface';
import {
  Results,
  ResultsRecord,
  QuantityUnit,
  ConversionFactors,
  ConversionFactorGroupKey,
} from '../../../shared/models/unit.interface';
import {
  getValueFromResultsRecord,
  getElementResults,
  getResultsRecordFromElementResults,
  getProjectResultsRecord,
} from '../../../shared/helpers/results.helpers';
import { isElement } from '../../../shared/helpers/recursive_element_helpers';
import { useSelectedVersion } from '../store/ui';
import { useSortedFlattenedElements } from './filter-elements.hook';
import { isMainCategoryElement } from '../../../shared/templates/categories';
import { useProposals } from './proposals.hook';
import { IProposal } from '../../../shared/models/proposals.interface';
import { ItemOrItemId } from '../../../shared/models/type_helpers.interface';
import { required } from '../../../shared/helpers/function_helpers';
import { useElementById, useVersions } from './useElement';
import {
  getProject,
  useProject,
  useProjectBuildingGFA,
} from '../store/project';
import { multiplyConversionFactors } from '../../../shared/helpers/conversion_helpers';
import { validatePositiveNumber } from '../../../shared/validation';
import { getMaxValuesInArray } from '../../../shared/helpers/math_helpers';

/**
 * Get latest results for each element in the entire project.
 */
export function useProjectResultsRecord(): ResultsRecord {
  const project = useProject();
  return React.useMemo(() => getProjectResultsRecord(project), [project]);
}

/**
 * Get latest conversion factors totals for each element for a specific version.
 * @param version The version to get the conversion factors for, if not provided, the selectedVersion is used.
 */
export function useVersionResultRecord(
  version?: IBuildingVersion,
): ResultsRecord {
  const selectedVersion = useSelectedVersion();
  if (!version) {
    version = selectedVersion;
  }

  return React.useMemo(() => {
    if (!version) {
      return {};
    }
    return getResultsRecordFromElementResults(getProject(), version);
  }, [version]);
}

export const useGetResultsPerGFA = () => {
  const selectedProjectGFA = useProjectBuildingGFA();

  return useCallback(
    <T extends Results | ConversionFactors | number>(
      results: T | undefined,
      externalProjectGFA = 0,
    ): T => {
      const gfa =
        externalProjectGFA > 0 ? externalProjectGFA : selectedProjectGFA;

      validatePositiveNumber(gfa);
      if (typeof results === 'number') {
        return (results / gfa) as T;
      }
      return multiplyConversionFactors(
        results,
        1 / validatePositiveNumber(gfa),
      );
    },
    [selectedProjectGFA],
  );
};

export const useResultsPerGFA = <
  T extends Results | ConversionFactors | number,
>(
  results: T | undefined,
): T => {
  const getResultsPerGFA = useGetResultsPerGFA();
  return useMemo(() => {
    return getResultsPerGFA(results);
  }, [results, getResultsPerGFA]);
};

/**
 * Get the largest results in version
 */
export const useElementMaxResults = (): Results => {
  const elements = useSortedFlattenedElements();
  const resultRecord = useVersionResultRecord();
  const proposals = useProposals();
  const resultRecords = useMemo(
    () => [
      resultRecord,
      ...proposals.map((proposal) => proposal.resultsRecord ?? {}),
    ],
    [proposals, resultRecord],
  );

  return useMemo(() => {
    const resultArray: Results[] = elements
      .filter((e) => !isMainCategoryElement(e)) // No main category elements
      .filter((e) => !isElement(e) || (!e.isDeactivated && !e.isHidden)) // Filter out deactivated and hidden elements
      .flatMap((el) => resultRecords.map((r) => r?.[el.id] ?? {}));

    return getMaxValuesInArray(resultArray);
  }, [elements, resultRecords]);
};

/**
 * Use results for a specific elements
 * @param element
 * @param inProposal
 * @returns
 */
export const useElementResults = (
  elementOrId: ItemOrItemId<OneOfElements> | undefined,
  inProposal?: ItemOrItemId<IProposal>,
): Results => {
  const version = required(useSelectedVersion());
  const element = useElementById(elementOrId);

  return useMemo(
    () => getElementResults(getProject(), version, element, inProposal),
    [version, element, inProposal],
  );
};

export const useProjectVersionsMax = (
  unit: QuantityUnit | ConversionFactorGroupKey = 'co2e',
): number => {
  const versions = useVersions();
  const projectsTotals = useProjectResultsRecord();
  return useMemo(() => {
    const versionFactors = versions.map((version) =>
      getValueFromResultsRecord(projectsTotals, version.id, unit),
    );
    return Math.max(...versionFactors, 0);
  }, [projectsTotals, unit, versions]);
};
