import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { Results } from '../../../shared/models/unit.interface';
import { formatValue } from '../../../shared/helpers/math_helpers';
import { isDefined } from '../../../shared/helpers/array_helpers';
import { makeStyles } from 'tss-react/mui';
import { getConversionFactorValue } from '../../../shared/helpers/conversion-factors.helpers';
import { useProjectCostIsDisabled } from '../store/project';
interface IResultsTooltipProps {
  results: Results;
  children: React.ReactElement;
  perGFA?: boolean;
}

export const ResultsTooltip: React.FC<IResultsTooltipProps> = ({
  results,
  children,
  perGFA = false,
}) => {
  const table = useMemo(
    () => <TooltipTable results={results} perGFA={perGFA} />,
    [results, perGFA],
  );

  return (
    <Tooltip title={table}>
      <div style={containerStyle}>{children}</div>
    </Tooltip>
  );
};

const TooltipTable: React.FC<{ results: Results; perGFA?: boolean }> = ({
  results,
  perGFA,
}) => {
  const { classes } = useStyles();
  const disableCost = useProjectCostIsDisabled();
  const co2eTotal = getConversionFactorValue(results, 'co2e');
  const sekTotal = getConversionFactorValue(results, 'sek');
  const rows: React.ReactNode[] = [];

  rows.push(
    getRow(
      'A1-A3',
      results['co2e_A1-A3'],
      results['sek_A1-A3'],
      perGFA,
      disableCost,
    ),
  );
  rows.push(
    getRow('A4', results['co2e_A4'], results['sek_B4'], perGFA, disableCost),
  );
  rows.push(getRow('A5', results['co2e_A5'], 0, perGFA, disableCost));
  rows.push(
    getRow('B2', results['co2e_B2'], results['sek_B2'], perGFA, disableCost),
  );
  rows.push(
    getRow('B4', results['co2e_B4'], results['sek_B4'], perGFA, disableCost),
  );
  rows.push(
    getRow('B6', results['co2e_B6'], results['sek_B6'], perGFA, disableCost),
  );
  rows.push(getRow('B7', 0, results['sek_B7'], perGFA, disableCost));
  rows.push(getRow('Total', co2eTotal, sekTotal, perGFA, disableCost));

  // Remove empty rows
  const filteredRows = rows.filter(isDefined);

  if (filteredRows.length !== 0) {
    return (
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow key="tooltip-row-header">
            <TableCell padding="none"></TableCell>
            <TableCell align="center">CO2</TableCell>
            {!disableCost && <TableCell align="center">SEK</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>{filteredRows}</TableBody>
      </Table>
    );
  }
};

const containerStyle: React.CSSProperties = {
  width: '100%',
};

const useStyles = makeStyles()(({ spacing }) => ({
  table: {
    width: '100%',
    color: 'white',
    [`& .${tableCellClasses.root}`]: {
      borderBottom: 'none',
      color: 'white',
      paddingTop: spacing(0.25),
      paddingBottom: spacing(0.25),
      whiteSpace: 'nowrap',
    },
  },
}));

const getRow = (
  label: string,
  co2: number = 0,
  cost: number = 0,
  perGFA = false,
  disableCost = false,
): undefined | React.ReactNode => {
  if (!co2 && !cost && label !== 'Total') return null;
  return (
    <TableRow key={`tooltip-row-${label}`}>
      <TableCell align="right" padding="none" sx={{ fontWeight: 500 }}>
        {label}
      </TableCell>
      <TableCell align="right">
        {formatValue(co2, 'co2e_A1-A3', {
          perUnit: perGFA ? 'GFA' : undefined,
          showUnit: true,
        })}
      </TableCell>
      {!disableCost && (
        <TableCell align="right">
          {formatValue(cost, 'kSEK', {
            perUnit: perGFA ? 'GFA' : undefined,
            showUnit: true,
          })}
        </TableCell>
      )}
    </TableRow>
  );
};
