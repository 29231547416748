import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, OutlinedTextFieldProps } from '@mui/material';
import { IElement } from '../../../../../shared/models/project.interface';
import { useIsSelected, useUIState } from '../../../store/ui/ui.hook';
import {
  getSelectedElementQuantityProperty,
  isElementQuantityName,
  isElementQuantityProperty,
  selectElementQuantity,
} from '../../../../../shared/helpers/element_quantity_helpers';
import { useIsReadonly } from '../../../hooks/user.hook';

import { useToggleElementExpanded } from '../../../hooks/expand-elements.hook';
import ExpandButton from '../Buttons/ExpandButton';
import { Row } from '../Row';
import { RowCell } from '../RowCell';
import {
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  ELEMENT_LIST_ITEM_HEIGHT,
} from '../list.constants';
import { useBooleanState } from '../../../hooks/hooks';
import {
  useIsMenuOpen,
  useTriggerContextMenu,
} from '../../../hooks/menu.hooks';
import { LIST_BORDER, useListRowStyles } from '../list.style';
import { useNavigateTo } from '../../../hooks/router.hooks';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import CreateSiblingButton, {
  useShouldShowCreateSiblingButton,
} from '../../CreateSiblingButton';
import { useSortedElementCommments } from '../../../store/comment/comments.hook';
import CommentsIconButton from '../../Comments/CommentsIconButton';
import ElementBarCharts from '../../charts/ElementBarCharts';
import ElementPropertyExpressionInput from '../../SidePanel/Element/Property/ElementPropertyExpression';
import { getElementVersionId } from '../../../../../shared/helpers/element-version.helpers';
import { useIsDeactivated } from '../../../hooks/useElement';
import InlineTextField from '../../InlineTextField';
import { useSetNameOfElements } from '../../../hooks/element-name.hook';
import { useParentMainCategoryId } from '../../../hooks/main-categories.hook';
import MainCategoryButton from '../Buttons/MainCategoryButton';
import VisibilityButton from '../Buttons/VisibilityButton';
import { useIsFirstElementVersionInList } from '../../../hooks/element-version.hook';
import NodonSelect from '../../NodonSelect';
import { sortBy } from 'lodash';
import { useUpdateElements } from '../../../store/project';
import { quantityIcons } from '../../Icon';
import { useAllwaysVisibleQuantities } from '../../SidePanel/Element/ElementQuantities';
import { ElementQuantityName } from '../../../../../shared/models/element_quantities.interface';
import ElementKebabMenu from '../../menus/KebabMenu/KebabMenus/ElementKebabMenu';
import { isExpandableElement } from '../../../../../shared/helpers/element_helpers';
import RecipeIconButton from '../../SidePanel/Element/Recipe/RecipeIconButton';

interface IElementListItemInput {
  element: IElement;
  autoFocus?: boolean;
  indentation?: number;
}

const ElementListItem: React.FC<IElementListItemInput> = ({
  element,
  autoFocus,
  indentation = 0,
}) => {
  const {
    setAddedElementId,
    selectedElementCategoryId,
    setShowProjectDetailsEditor,
  } = useUIState(
    'setAddedElementId',
    'selectedElementCategoryId',
    'setShowProjectDetailsEditor',
  );

  const { classes: listClasses, theme } = useListRowStyles();

  const navigateTo = useNavigateTo();
  const updateElements = useUpdateElements();
  const setNameOfElements = useSetNameOfElements();
  const toggleElementExpanded = useToggleElementExpanded(element);
  const triggerContextMenu = useTriggerContextMenu(element.id);

  const comments = useSortedElementCommments(element.id);

  const elementVersionId = getElementVersionId(element);
  const parentMainCategoryId = useParentMainCategoryId(element);
  const visibleQuantities = useAllwaysVisibleQuantities(element);

  const readonly = useIsReadonly();
  const selected = useIsSelected(element);
  const deactivated = useIsDeactivated(element);
  const showCreateSiblingButton = useShouldShowCreateSiblingButton(element);
  const isKebabMenuOpen = useIsMenuOpen(element.id);
  const isFirstVersionInList = useIsFirstElementVersionInList(element);
  const showMainCategoryIcon =
    !!parentMainCategoryId && !selectedElementCategoryId && !deactivated;

  const isExpandAllowed = isExpandableElement(element);

  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);

  const [hover, startHover, endHover] = useBooleanState();
  const [kebabMenuAnchor, setKebabMenuAnchor] = useState<Element>();

  const selectedQuantityProperty = getSelectedElementQuantityProperty(element);

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        if (autoFocus) {
          e.currentTarget.select();
        }
      },
      onBlur: () => {
        if (autoFocus) {
          setAddedElementId(undefined);
        }
      },
      InputProps: {
        inputProps: {
          sx: {
            ' &:not(:focus)::placeholder': {
              opacity: 1, // Show placeholder with no transparency to not confuse with disabled state
            },
          },
        },
      },
    }),
    [autoFocus, setAddedElementId],
  );

  const selectableQuantityOptions: {
    value: ElementQuantityName;
    icon?: React.ReactNode;
    selected?: boolean;
  }[] = useMemo(() => {
    if (
      !visibleQuantities.length ||
      !isElementQuantityProperty(selectedQuantityProperty)
    ) {
      return [];
    }

    const names = visibleQuantities.map(({ name }) => {
      const quantityName = name as keyof typeof quantityIcons;

      const icon =
        quantityName in quantityIcons ? quantityIcons[quantityName] : null;

      const selected = [
        selectedQuantityProperty.name,
        element.selectedQuantity,
      ].includes(name as ElementQuantityName);

      return {
        value: name as ElementQuantityName,
        icon,
        selected,
      };
    });

    return sortBy(names, 'icon');
  }, [selectedQuantityProperty, visibleQuantities, element.selectedQuantity]);

  const handleNameInputClick = useMouseEventCallback(() => {
    if (selected || !elementVersionId) {
      startEditingName();
      return;
    }
    navigateTo({ elementId: element.id });
  });

  const handleRowClick = useMouseEventCallback(
    () => {
      if (!isKebabMenuOpen || readonly) {
        navigateTo({ elementId: element.id });
        setShowProjectDetailsEditor(false);
      }
    },
    { ignoreInputEvents: true },
  );

  const handleQuantityChange = useCallback(
    async (name: string) => {
      if (isElementQuantityName(name)) {
        return await updateElements(selectElementQuantity(element, name));
      }
    },
    [element, updateElements],
  );

  const handleKebabMenuClose = useCallback(() => {
    setKebabMenuAnchor(undefined);
    endHover();
  }, [endHover]);

  const handleSaveName = useCallback(
    async (name: string) => {
      await setNameOfElements(name, element);
      stopEditingName();
    },
    [element, setNameOfElements, stopEditingName],
  );

  // Start editing the name if autoFocus is set
  useEffect(() => {
    autoFocus && startEditingName();
  }, [autoFocus, startEditingName]);

  return (
    <Box
      width="100%"
      onMouseLeave={endHover}
      onMouseOver={startHover}
      onClick={handleRowClick}
      onDoubleClick={toggleElementExpanded}
    >
      <Row
        classes={listClasses}
        deactivated={element.isDeactivated}
        height={ELEMENT_LIST_ITEM_HEIGHT}
        onContextMenu={triggerContextMenu}
        hover={hover}
        padding={true}
        selected={selected}
        spacing={LIST_SPACING.DEFAULT}
        borderTop={isFirstVersionInList ? LIST_BORDER : undefined}
      >
        {/* CONTENT. Group content in a shared cell to make sure we can align bar charts as one */}
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT}
          borderRight={LIST_BORDER}
          paddingRight={LIST_SPACING}
        >
          <Row height={ELEMENT_LIST_ITEM_HEIGHT} spacing={LIST_SPACING.DEFAULT}>
            {/* Visibility icon */}
            <RowCell width={ROOT_CELL_WIDTH.ICON}>
              {showMainCategoryIcon && (
                <MainCategoryButton id={parentMainCategoryId} />
              )}
              {(hover || selected || element.isDeactivated) &&
                !showMainCategoryIcon && <VisibilityButton element={element} />}
            </RowCell>

            {/* Expand icon */}
            <RowCell width={ROOT_CELL_WIDTH.ICON} indentation={indentation}>
              <ExpandButton element={element} disabled={!isExpandAllowed} />
            </RowCell>

            {/* Name input */}
            <RowCell
              width={CONTENT_CELL_WIDTH.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
            >
              <InlineTextField
                autoWidth
                variant="subtitle1"
                value={element.name}
                defaultValue={element.fallbackName}
                strikeThrough={element.isDeactivated}
                editing={isEditingName}
                readonly={!selected}
                inactive={deactivated}
                textFieldProps={nameInputProps}
                onClick={handleNameInputClick}
                onSave={handleSaveName}
                onCancel={stopEditingName}
              />

              {/* Recipe selector button */}
              {(hover || selected) && <RecipeIconButton element={element} />}
            </RowCell>

            {/* Expression input (only if a quantity is selected) */}
            <RowCell align="right" width={CONTENT_CELL_WIDTH.EXPRESSION}>
              {selectedQuantityProperty && (
                <>
                  <ElementPropertyExpressionInput
                    property={selectedQuantityProperty}
                    element={element}
                  />
                  <NodonSelect
                    options={selectableQuantityOptions}
                    onChange={handleQuantityChange}
                    buttonStyles={{
                      visibility:
                        !readonly &&
                        selectableQuantityOptions.length > 0 &&
                        (hover || selected)
                          ? 'visible'
                          : 'hidden',
                      marginRight: 3,
                    }}
                    tooltipTitle="Select quantity"
                  />
                </>
              )}
            </RowCell>
          </Row>
        </RowCell>

        {/* Bar chart */}
        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <ElementBarCharts element={element} />
        </RowCell>

        {/* Align bar width with proposal bars */}
        <RowCell
          width={
            (CONTENT_CELL_WIDTH.LABEL - CONTENT_CELL_WIDTH.ICON) * 2 -
            parseInt(theme.spacing(LIST_SPACING.DEFAULT))
          }
        />

        <RowCell width={CONTENT_CELL_WIDTH.ICON} align="right">
          {comments.length > 0 && <CommentsIconButton comments={comments} />}
        </RowCell>

        {/* Kebab menu */}
        <RowCell width={CONTENT_CELL_WIDTH.ICON} hideOnPrint align="center">
          {(hover || kebabMenuAnchor || isKebabMenuOpen) && (
            <ElementKebabMenu
              element={element}
              anchor={kebabMenuAnchor}
              onOpen={setKebabMenuAnchor}
              onClose={handleKebabMenuClose}
            />
          )}
        </RowCell>
      </Row>
      {showCreateSiblingButton && (
        <CreateSiblingButton element={element} indentation={indentation} />
      )}
    </Box>
  );
};

export default memo(ElementListItem);
