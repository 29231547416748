import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { OneOfProductListChildren } from '../../../../../../shared/models/project.interface';
import {
  useIsSelected,
  useSelectedVersion,
} from '../../../../store/ui/ui.hook';
import { useToggleElementExpanded } from '../../../../hooks/expand-elements.hook';
import ExpandButton from '../../Buttons/ExpandButton';
import { Row } from '../../Row';
import { RowCell } from '../../RowCell';
import {
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  ELEMENT_LIST_ITEM_HEIGHT,
} from '../../list.constants';
import { useBooleanState } from '../../../../hooks/hooks';
import { useTriggerContextMenu } from '../../../../hooks/menu.hooks';
import { LIST_BORDER, useListRowStyles } from '../../list.style';
import { useMouseEventCallback } from '../../../../hooks/events.hook';
import {
  hasChildren,
  isProductListCategoryGroup,
  isProductListGroup,
  isProductListItem,
} from '../../../../../../shared/helpers/recursive_element_helpers';
import { useIsFirstElementVersionInList } from '../../../../hooks/element-version.hook';
import ExpressionTypography from '../../../ExpressionTypography';
import { useResultsPerGFA } from '../../../../hooks/results.hook';
import { required } from '../../../../../../shared/helpers/function_helpers';
import { useProductListMaxResults } from '../../../../hooks/product-list.hook';
import {
  getProductListElementName,
  getProductListEPDNames,
  PRODUCT_LIST_PATH_MAX_LENGTH,
} from '../../../../helpers/product-list.helpers';
import { NodonTheme } from '../../../../style';
import { EllipsisText } from '../../../EllipsisText';
import { useNavigateTo } from '../../../../hooks/router.hooks';
import {
  useProposalColor,
  useSelectedProposal,
} from '../../../../hooks/proposals.hook';
import ResultsBarCharts from '../../../../components/charts/ResultsBarCharts';
import { useProjectCostIsDisabled } from '../../../../store/project';
import { getConversionFactorValue } from '../../../../../../shared/helpers/conversion-factors.helpers';

interface ProductListItemProps {
  element: OneOfProductListChildren;
  indentation?: number;
}

const ProductListItem: React.FC<ProductListItemProps> = ({
  element,
  indentation = 0,
}) => {
  const navigateTo = useNavigateTo();
  const { classes: listClasses } = useListRowStyles();
  const disableCost = useProjectCostIsDisabled();

  const selected = useIsSelected(element);
  const isFirstVersionInList = useIsFirstElementVersionInList(element);
  const isExpandAllowed = hasChildren(element);

  const maxResults = useProductListMaxResults();

  const proposal = useSelectedProposal();
  const proposalColor = useProposalColor(proposal?.id ?? '');

  const resultsPerGFA = useResultsPerGFA(element.results);
  const resultsPerGFAmax = useResultsPerGFA(maxResults);

  const co2ePerGFA = getConversionFactorValue(resultsPerGFA, 'co2e');
  const costPerGFA = getConversionFactorValue(resultsPerGFA, 'sek');

  const co2ePerGfaMax = getConversionFactorValue(resultsPerGFAmax, 'co2e');
  const costPerGfaMax = getConversionFactorValue(resultsPerGFAmax, 'sek');

  const [hover, startHover, endHover] = useBooleanState();

  const toggleElementExpanded = useToggleElementExpanded(element);
  const triggerContextMenu = useTriggerContextMenu(element.id);

  const onRowClick = useMouseEventCallback(() => {
    if (isProductListGroup(element) || isProductListItem(element)) {
      navigateTo({ elementId: element.id });
    }
  });

  return (
    <Box
      width="100%"
      onMouseLeave={endHover}
      onMouseOver={startHover}
      onClick={onRowClick}
      onDoubleClick={toggleElementExpanded}
    >
      <Row
        classes={listClasses}
        height={ELEMENT_LIST_ITEM_HEIGHT}
        onContextMenu={triggerContextMenu}
        hover={hover}
        padding={true}
        selected={selected}
        spacing={LIST_SPACING.DEFAULT}
        borderTop={isFirstVersionInList ? LIST_BORDER : undefined}
      >
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT}
          borderRight={LIST_BORDER}
          paddingRight={LIST_SPACING}
        >
          <Row height={ELEMENT_LIST_ITEM_HEIGHT} spacing={LIST_SPACING.DEFAULT}>
            <RowCell width={ROOT_CELL_WIDTH.ICON} indentation={indentation}>
              <ExpandButton element={element} disabled={!isExpandAllowed} />
            </RowCell>

            <RowCell
              width={CONTENT_CELL_WIDTH.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
            >
              <ProductListElementName element={element} />
            </RowCell>

            <RowCell align="right" width={CONTENT_CELL_WIDTH.UNIT}>
              <ExpressionTypography
                value={element.results?.[element.unit]}
                unit={element.unit}
              />
            </RowCell>
          </Row>
        </RowCell>

        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <ResultsBarCharts
            co2e={co2ePerGFA}
            co2eMax={co2ePerGfaMax}
            cost={costPerGFA}
            costMax={costPerGfaMax}
            color={proposalColor}
            disableCost={disableCost}
          />
        </RowCell>
      </Row>
    </Box>
  );
};

interface ProductListElementNameProps {
  element: OneOfProductListChildren;
}

const ProductListElementName = ({ element }: ProductListElementNameProps) => {
  const version = required(useSelectedVersion());

  if (isProductListCategoryGroup(element) || isProductListItem(element)) {
    const name = getProductListElementName(element, version.products);

    if (isProductListCategoryGroup(element)) {
      return (
        <Typography variant="h6" textTransform="uppercase">
          {name}
        </Typography>
      );
    }

    const tooltipTitle = getProductListElementName(element, {
      disableTruncation: true,
    });

    return (
      <Typography variant="body2" display="flex" overflow="auto">
        <EllipsisText
          tooltipTitle={
            tooltipTitle.length > PRODUCT_LIST_PATH_MAX_LENGTH
              ? tooltipTitle
              : undefined
          }
        >
          {name}
        </EllipsisText>
      </Typography>
    );
  }

  const [name, genericName] = getProductListEPDNames(element, version.products);

  if (genericName) {
    return (
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        maxWidth={CONTENT_CELL_WIDTH.NAME * 2}
      >
        <Typography
          variant="body2"
          display="flex"
          color={NodonTheme.palette.neutral.light}
        >
          <EllipsisText>{genericName}: </EllipsisText>
        </Typography>

        <Typography variant="body2" display="flex" overflow="auto">
          <EllipsisText>{name}</EllipsisText>
        </Typography>
      </Box>
    );
  }
  return <Typography variant="body2">{name}</Typography>;
};

export default memo(ProductListItem);
