import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useEPDIdsAppliedToGenericProduct,
  useProductSwitch,
} from '../../hooks/product-switch.hook';
import { required } from '../../../../shared/helpers/function_helpers';
import { openEditProductDialog } from '../../projects/EditProject/EditProductDialog';
import { useProductsLookup } from '../../store/product';
import { useSelectedVersion, useUIState } from '../../store/ui';
import ProductSelector from '../../projects/EditProject/ProductSelector';
import EPDSelectMenu from './EPDSelectMenu';
import EPDButtonMenu from './EPDButtonMenu';
import { useEPDMenuItems } from './EPDMenuItems';
import {
  IProduct,
  ProductID,
} from '../../../../shared/models/product.interface';
import { getProductListElementId } from '../../helpers/product-list.helpers';
import { isProductElement } from '../../../../shared/helpers/recursive_element_helpers';
import { ProductSettingsProps } from '../SidePanel/ProductSettings';
import { getProductIdsInElement } from '../../../../shared/helpers/product_helpers';
import { useNavigateTo } from '../../hooks/router.hooks';
import { isDefined } from '../../../../shared/helpers/array_helpers';

interface EPDMenuProps
  extends Pick<
    ProductSettingsProps,
    | 'product_id'
    | 'generic_product_id'
    | 'productCategoryElement'
    | 'generatedElements'
  > {
  variant?: 'button' | 'select';
}

const EPDMenu: React.FC<EPDMenuProps> = ({
  variant = 'select',
  product_id,
  generic_product_id,
  productCategoryElement,
  generatedElements,
}) => {
  const { setSelectedProductListElementId, selectedPage } = useUIState(
    'setSelectedProductListElementId',
    'selectedPage',
  );
  const navigateTo = useNavigateTo();

  const selectedVersion = required(useSelectedVersion());
  const productsLookup = useProductsLookup();

  const hasEPD =
    generic_product_id && product_id && generic_product_id !== product_id;
  const genericProductId = generic_product_id ?? product_id;

  const productCategoryElementChildren = useMemo(
    () =>
      productCategoryElement?.elements
        .filter(isProductElement)
        .filter((child) =>
          getProductIdsInElement(child).includes(genericProductId),
        ) ?? [],
    [productCategoryElement, genericProductId],
  );

  const {
    isProductSelectorOpen,
    openProductSelector,
    closeProductSelector,
    switchProducts,
    clearEPD,
  } = useProductSwitch(
    ...(generatedElements ?? productCategoryElementChildren),
  );

  /* 
  Id of the EPD item that the kebab sub menu has been opened from. 
  Used to prevent closing the parent menu when the kebab menu is closed.
  */
  const [kebabMenuAnchorId, setKebabMenuAnchorId] = useState('');
  const [selectMenuValue, setSelectMenuValue] = useState('');
  const [isSelectedButtonMenuValue, setIsSelectedButtonMenuValue] =
    useState(false);

  const appliedEPD = useMemo(
    () => (hasEPD ? selectedVersion.products[product_id] : undefined),
    [hasEPD, product_id, selectedVersion.products],
  );

  const localstorageIds = useEPDIdsAppliedToGenericProduct(genericProductId);

  const mappedEPDs = useMemo(() => {
    const epdsMappedInLocalstorage: IProduct[] = [];

    for (const id of localstorageIds ?? []) {
      const product = productsLookup[id];

      // epds in localstorage might not be in the lookup
      if (product) epdsMappedInLocalstorage.push(product);
    }

    const epdsMappedInDatabase = Object.values(productsLookup).filter(
      ({ generic_id }) => generic_id === genericProductId,
    );

    // create a map to remove duplicates
    const uniqueMap = new Map(
      [
        ...epdsMappedInLocalstorage,
        ...epdsMappedInDatabase,
        // Always include the active EPD in the list
        appliedEPD,
      ]
        .filter(isDefined)
        .map((product) => [product.id, product]),
    );

    return Array.from(uniqueMap.values());
  }, [productsLookup, genericProductId, appliedEPD, localstorageIds]);

  const setProductListElementId = useCallback(
    (id: ProductID) => {
      if (selectedPage === 'products') {
        const elementId = getProductListElementId(id, genericProductId);
        setSelectedProductListElementId(elementId);
        navigateTo({ elementId });
      }
    },
    [
      selectedPage,
      genericProductId,
      setSelectedProductListElementId,
      navigateTo,
    ],
  );

  const handleSaveProduct = useCallback(
    async (id: ProductID) => {
      await switchProducts(id, genericProductId);

      closeProductSelector();
      setSelectMenuValue(id);
      setProductListElementId(id);
    },
    [
      switchProducts,
      genericProductId,
      closeProductSelector,
      setProductListElementId,
    ],
  );

  const handleClearEPD = useCallback(
    async (id?: ProductID) => {
      if (!id || id === selectMenuValue) {
        await clearEPD();
        setSelectMenuValue('');
        setProductListElementId(genericProductId);
      }
      setKebabMenuAnchorId('');
    },
    [selectMenuValue, clearEPD, setProductListElementId, genericProductId],
  );

  const handleEditEPD = useCallback(
    async (editId?: ProductID) => {
      const product = await openEditProductDialog({
        parent: !editId ? productCategoryElement : undefined,
        product: editId ? productsLookup[editId] : undefined,
      });

      // If not cancelled
      if (product) {
        if (editId && editId !== appliedEPD?.id) {
          // only do a switch if the edited epd is the applied one
          return;
        }
        if (editId) {
          // clear epd to replace the product in version with the edited product (should be handled elsewhere?)
          await clearEPD();
        }
        await switchProducts(product.id);

        handleSaveProduct(product.id);
        setKebabMenuAnchorId('');
      }
    },
    [
      appliedEPD?.id,
      clearEPD,
      handleSaveProduct,
      productCategoryElement,
      productsLookup,
      switchProducts,
    ],
  );

  const handleSelectEPD = useCallback(
    async (id: string) => {
      // set this to trigger the button menu to close
      setIsSelectedButtonMenuValue(true);

      if (id === 'none') return handleClearEPD();
      if (id === 'other') return openProductSelector();
      if (id === 'new') return handleEditEPD();

      try {
        // id = product id
        handleSaveProduct(id);
      } catch {
        setSelectMenuValue(appliedEPD?.id ?? '');
      }
    },
    [
      appliedEPD?.id,
      handleClearEPD,
      handleEditEPD,
      handleSaveProduct,
      openProductSelector,
    ],
  );

  const handleProductSelectorSave = useCallback(
    (pid: string, gid?: string, isSelectedEPD = false) => {
      if (isSelectedEPD || genericProductId === gid) {
        handleSaveProduct(pid);
      }
    },
    [genericProductId, handleSaveProduct],
  );

  const handleOpenKebab = useCallback(
    (id: ProductID) => {
      setKebabMenuAnchorId(id);
    },
    [setKebabMenuAnchorId],
  );

  const handleCloseKebab = useCallback(() => {
    setKebabMenuAnchorId('');
  }, [setKebabMenuAnchorId]);

  const menuItems = useEPDMenuItems({
    variant,
    genericProductId,
    kebabMenuAnchorId,
    parentMenuValue: selectMenuValue,
    epds: mappedEPDs,
    onSelect: handleSelectEPD,
    onEdit: handleEditEPD,
    onRemove: handleClearEPD,
    onOpenKebab: handleOpenKebab,
    onCloseKebab: handleCloseKebab,
  });

  useEffect(() => {
    const mappedIds = mappedEPDs.map(({ id }) => id);
    const appliedId = appliedEPD?.id ?? '';

    setSelectMenuValue(mappedIds.includes(appliedId) ? appliedId : '');
  }, [appliedEPD, mappedEPDs, selectMenuValue]);

  return (
    <>
      {variant === 'select' && (
        <EPDSelectMenu
          selectedEpdId={
            selectMenuValue === appliedEPD?.id ? selectMenuValue : ''
          }
          items={menuItems}
          epds={mappedEPDs}
          kebabMenuAnchorId={kebabMenuAnchorId}
          selectEPD={handleSelectEPD}
        />
      )}

      {variant === 'button' && (
        <EPDButtonMenu
          items={menuItems}
          selectedEPDName={appliedEPD?.name}
          preventClose={!isSelectedButtonMenuValue || !!kebabMenuAnchorId}
          setIsSelectedMenuValue={setIsSelectedButtonMenuValue}
        />
      )}

      {/* For performance reasone only render when it's open */}
      {isProductSelectorOpen && (
        <ProductSelector
          keepOpen
          open={isProductSelectorOpen}
          parent={productCategoryElement}
          productId={appliedEPD?.id}
          lockedTab="epd"
          onSave={handleProductSelectorSave}
          onClose={closeProductSelector}
        />
      )}
    </>
  );
};

export default EPDMenu;
