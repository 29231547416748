import {
  isCostUnit,
  isUnitB,
  isCO2eUnit,
} from '../../../../shared/helpers/unit_helpers';
import { isDefined } from '../../../../shared/helpers/array_helpers';
import { Results } from '../../../../shared/models/unit.interface';
import { sortBy } from 'lodash';
import { ISimpleBarChartValue } from './SimpleBarChart';

const OPACITY_B = 0.7;

export const getCo2eChartDataFromResults = (
  results: Results,
): ISimpleBarChartValue[] => {
  return sortBy(
    Object.entries(results)
      .map(([key, value]) =>
        isCO2eUnit(key)
          ? { label: key, value, opacity: isUnitB(key) ? OPACITY_B : 1 }
          : undefined,
      )
      .filter(isDefined),
    'label',
  );
};

export const getCostChartDataFromResults = (
  results: Results,
): ISimpleBarChartValue[] => {
  return sortBy(
    Object.entries(results)
      .map(([key, value]) =>
        isCostUnit(key)
          ? { label: key, value, opacity: isUnitB(key) ? OPACITY_B : 1 }
          : undefined,
      )
      .filter(isDefined),
    'label',
  );
};
