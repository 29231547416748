import { sortBy } from 'lodash';
import genericProducts from '../../../generic_products';
import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyType,
  ElementPropertyName,
  IFactorySelectProperty,
} from '../../../models/element_property.interface';
import { createProductTreeProcessor } from '../processor';
import {
  getConversionFactorValue,
  removeConversionFactorsByKey,
} from '../../../helpers/conversion-factors.helpers';
import { getElementPropertyResolvedCountByNameOrId } from '../../../helpers/element_property_helpers';

export const energyProducts = genericProducts.filter(
  ({ categories }) => categories.Boverket?.['Energy and fuel'],
);

const sorted = sortBy(energyProducts, 'name');

const selectProperty: IFactorySelectProperty = {
  name: 'products',
  type: ElementPropertyType.Select,
  count: sorted[0]?.name,
  options: sorted.map(({ name }) => name),
};

const processor = createProductTreeProcessor({
  levelProperties: [selectProperty],
  productTree: energyProducts.reduce(
    (acc, { id, name }) => ({ ...acc, [name]: id }),
    {},
  ),
});

/**
 * Move emission in A to B6 unless the sbef code is set to A5
 * @param results
 * @param context
 * @returns
 */
const mapResults: IElementCategory['mapResults'] = (results, context) => {
  const co2_A = getConversionFactorValue(results, 'co2e_A');
  const sek_A = getConversionFactorValue(results, 'sek_A');

  // If there is no co2e and sek, return the results as is
  if (!co2_A && !sek_A) {
    return results;
  }

  const sbef = getElementPropertyResolvedCountByNameOrId(
    context.categoryElement,
    ElementPropertyName.SBEFCode,
  );

  const isA5Sbef = typeof sbef === 'string' && sbef.includes('A5.');

  const costKey = isA5Sbef ? 'sek_A5' : 'sek_B6';
  const co2Key = isA5Sbef ? 'co2e_A5' : 'co2e_B6';

  if (co2_A || sek_A) {
    return {
      ...removeConversionFactorsByKey(results, 'co2e_A', 'sek_A'),
      [co2Key]: co2_A,
      [costKey]: sek_A,
    };
  }

  return results;
};

export const energy: IElementCategory = {
  ...processor,

  id: ElementCategoryID.Energy,
  name: 'Energy',
  color: 'rgba(255, 197, 2, 1)',
  defaultSelectedQuantity: 'energy',
  excludeMutualProperties: [ElementPropertyName.Lifetime],
  mapResults,
  getChildElements: (element) => {
    return (
      processor
        .getChildElements(element)
        // Convert kWh to MJ
        .map((p) => (p.unit === 'kWh' ? { ...p, unit: 'MJ' } : p))
    );
  },
  getQuantityProperties: () => ({ energy: {} }),
};
