import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import React, { CSSProperties, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import MultiLineTooltip from '../MultiLineTooltip';
import { orderBy, size, sortBy } from 'lodash';

export interface ISimpleBarChartInput {
  value?: number | ISimpleBarChartValue[] | number[] | Record<string, number>;
  maxValue?: number;
  color?: string;
  backgroundColor?: string;
  height?: number;
  tooltip?: string | string[];
}

export interface ISimpleBarChartValue {
  label?: string;
  value: number;
  color?: string;
  opacity?: number;
}

const SimpleBarChart: React.FC<ISimpleBarChartInput> = ({
  tooltip,
  value = 0,
  maxValue = 1,
  color = '#bbb',
  backgroundColor,
  height = 5,
}) => {
  height = Math.round(height);

  const { classes } = useStyles();

  const bgStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: backgroundColor ?? 'transparent',
    };
  }, [backgroundColor]);

  const values = useMemo(() => {
    let left = 0;

    return removeEmptyValues(toBarChartValues(value)).map((v) => {
      const width = getWidthInPercent(v.value, maxValue);
      const values = {
        ...v,
        width: `${width}%`,
        left: `${left}%`,
      };
      left += width;
      return values;
    });
  }, [value, maxValue]);

  const barParts = useMemo(() => {
    return values.map(
      ({ label, width, left, color: partColor, opacity }, index) => {
        return (
          <Box
            key={label ?? `bar-part-${index}`}
            style={{
              ...barPartStyle,
              width: `calc(${width} - 1px)`,
              minWidth: '1px',
              left,
              backgroundColor: partColor ?? color,
              opacity: opacity ?? 1,
            }}
          />
        );
      },
    );
  }, [values, color]);

  return (
    <Box className={classes.background} style={bgStyle} height={height + 'px'}>
      {typeof tooltip === 'string' ? (
        <Tooltip title={tooltip}>
          <Box>{barParts}</Box>
        </Tooltip>
      ) : (
        <MultiLineTooltip rows={tooltip} className={classes.tooltip}>
          <Box>{barParts}</Box>
        </MultiLineTooltip>
      )}
    </Box>
  );
};

/**
 * Convert the different types of input values to an array of bar chart values.
 * @param value
 * @returns
 */
const toBarChartValues = (
  value:
    | number
    | ISimpleBarChartValue[]
    | number[]
    | Record<string, number> = 0,
): ISimpleBarChartValue[] => {
  // Handle empty values
  if (typeof value === 'number') {
    return [{ value }];
  }
  // Handle empty values
  if (size(value) === 0) {
    return [{ value: 0 }];
  }
  if (Array.isArray(value)) {
    return sortBy(
      value.map((v) => (typeof v === 'number' ? { value: v } : v)),
      'label',
    );
  }

  return orderBy(
    Object.entries(value).map(([label, value]) => ({ label, value })),
    ['label', 'value'],
    ['asc', 'desc'],
  );
};

/**
 * Remove empty values from the bar chart values.
 * Make sure to keep at least one value even if it's 0.
 * @param values
 * @returns
 */
const removeEmptyValues = (
  values: ISimpleBarChartValue[],
): ISimpleBarChartValue[] => {
  const firstValue = values[0];
  if (!firstValue) {
    return [];
  }
  const filtered = values.filter((v) => v.value);
  return filtered.length > 0 ? filtered : [firstValue];
};

const getWidthInPercent = (value: number, maxValue: number): number => {
  return Math.max(0, (value / maxValue) * 100);
};

const useStyles = makeStyles()(({ palette }) => ({
  background: {
    display: 'flex',
    position: 'relative',
    alignContent: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  bar: {
    height: '100%',
    width: 0,
    minWidth: '1px',
    transition: 'width 0.5s ease',
    backgroundColor: palette.primary.main,
  },
  tooltip: {
    justifyContent: 'flex-start',
  },
}));

const barPartStyle: CSSProperties = {
  height: '100%',
  width: 0,
  minWidth: '0',
  maxWidth: '100%',
  position: 'absolute',
  left: 0,
  // transition: 'width 0.5s ease',
};

export default SimpleBarChart;
