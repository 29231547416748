import { getElementPropertyResolvedCountByNameOrId } from '../../../helpers/element_property_helpers';
import { getBuildingLifetime } from '../../../helpers/project_helpers';
import { isEnvironment } from '../../../helpers/environment.helpers';
import {
  ElementCategoryID,
  IElementCategory,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyType,
  ElementPropertyName,
  IFactoryProperty,
} from '../../../models/element_property.interface';
import {
  getConversionFactorValue,
  removeConversionFactorsByKey,
} from '../../../helpers/conversion-factors.helpers';

export enum MaintenancePropertyName {
  Reoccurance = 'reoccurance',
}

const properties: IFactoryProperty[] = [
  {
    name: MaintenancePropertyName.Reoccurance,
    type: ElementPropertyType.Expression,
    min: 0,
    fallbackCount: 'building_lifetime / 2',
    unit: 'year',
  },
];

/**
 * Map results from A to B2
 * @param results
 * @param context
 * @returns
 */
const mapResults: IElementCategory['mapResults'] = (results, context) => {
  const { project, categoryElement } = context;

  // Already applied B2, make sure we don't apply it again
  if (results.co2e_B2 || results.sek_B2) {
    return results;
  }

  const reoccurance = getElementPropertyResolvedCountByNameOrId(
    categoryElement,
    MaintenancePropertyName.Reoccurance,
    0,
  );

  const lifetime = getBuildingLifetime(project);

  // How many times the maintenance is needed before the building is demolished (use decimal method to spread the cost more evenly)
  const cycles = Math.max(lifetime / reoccurance, 0);

  return removeConversionFactorsByKey(
    {
      ...results,
      co2e_B2: getConversionFactorValue(results, 'co2e_A') * cycles,
      sek_B2: getConversionFactorValue(results, 'sek_A') * cycles,
    },
    'co2e_A',
    'sek_A',
    'co2e_B4', // no lifetime co2e in maintenance
    'sek_B4', // no lifetime cost in maintenance
  );
};

export const maintenance: IElementCategory = {
  id: ElementCategoryID.Maintenance,
  name: 'Maintenance',
  excludeMutualProperties: [ElementPropertyName.Lifetime],
  disabled: isEnvironment('production'),
  properties,
  mapResults,
};
