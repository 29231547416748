import { useCallback } from 'react';
import { ProductID } from '../../../shared/models/product.interface';
import { useUpdateElements } from '../store/project';
import { replaceProductInElement } from '../../../shared/helpers/element_helpers';
import { IProductElement } from '../../../shared/models/project.interface';
import { getProductByIdFromVersionOrStore } from './useProducts';
import { useBooleanState } from './hooks';
import { isDefined } from '../../../shared/helpers/array_helpers';
import {
  getProductIdsInElement,
  isCustomProduct,
} from '../../../shared/helpers/product_helpers';
import { createLocalStorageRecordStore } from '../helpers/local-storage.helpers';
import { useObjectMemo } from './object.hook';

export const {
  getItem: getEPDIdsAppliedToGenericProduct,
  setItem: setEPDIdsAppliedToGenericProduct,
  useStoreItem: useEPDIdsAppliedToGenericProduct,
} = createLocalStorageRecordStore<ProductID, ProductID[]>(
  'epds_applied_to_generic_products',
);

interface ProductSwitchUtils {
  isProductSelectorOpen: boolean;

  openProductSelector: () => void;

  closeProductSelector: () => void;

  /** Switch one or more products */
  switchProducts: (id: ProductID, genericId?: ProductID) => Promise<void>;

  /** Clear the EPD mapping from the product element */
  clearEPD: () => Promise<void>;
}

/**
 *
 * @param productElements
 * @returns
 */
export const useProductSwitch = (
  ...productElements: IProductElement[]
): ProductSwitchUtils => {
  if (!productElements.length) {
    throw new Error('Product elements are not provided');
  }

  const updateElements = useUpdateElements();

  const [isProductSelectorOpen, openProductSelector, closeProductSelector] =
    useBooleanState(false);

  /**
   * Remove the EPD mapping from the product elements
   */
  const clearEPD = useCallback(async () => {
    const toUpdate = productElements
      .map((productElement) => {
        if (productElement.generic_product_id) {
          return {
            ...productElement,
            generic_product_id: undefined,
            product_id: productElement.generic_product_id,
          };
        }
      })
      .filter(isDefined);
    await updateElements(...toUpdate);
  }, [productElements, updateElements]);

  // replace product in one or multiple elements
  const switchProducts = useCallback(
    async (id: ProductID, genericId = id) => {
      const newProduct = getProductByIdFromVersionOrStore(
        id,
        isCustomProduct(id),
      );

      const elementsToUpdate = productElements
        .map((productElement) => {
          if (!getProductIdsInElement(productElement).includes(genericId)) {
            return undefined;
          }
          return replaceProductInElement(productElement, newProduct);
        })
        .filter(isDefined);

      if (elementsToUpdate.length) {
        // First is enough to update
        const [firstElement] = elementsToUpdate;

        const genericId = firstElement?.generic_product_id;
        const epdId = firstElement?.product_id;

        // Make sure epd is mapped to the generic product (to reuse conversion factors)
        if (genericId && epdId) {
          const ids = getEPDIdsAppliedToGenericProduct(genericId) ?? [];
          const uniqueIds = new Set([...ids, epdId]);
          setEPDIdsAppliedToGenericProduct(genericId, [...uniqueIds]);
        }
        await updateElements(...elementsToUpdate);
      }

      closeProductSelector();
    },
    [closeProductSelector, productElements, updateElements],
  );

  return useObjectMemo({
    isProductSelectorOpen,
    clearEPD,
    closeProductSelector,
    openProductSelector,
    switchProducts,
  });
};
