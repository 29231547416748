import { ModelTimestamps } from './base.interface';
import { QuantityUnit, ConversionFactors, Results } from './unit.interface';
import { ElementKind, IProductElement } from './project.interface';
import { Replace, SemiPartial } from '../models/type_helpers.interface';
import { Characteristics } from './product-characteristics.interface';
import { ElementCategoryID } from './element_categories.interface';
import { PropertyResolvedCountRecord } from './element_property.interface';
import { ElementPath } from '../helpers/recursive_element_helpers';

export const BOVERKET_ID_PREFIX = 'boverket_sv-SE_';
export const NODON_ID_PREFIX = 'nodon_';
export const CUSTOM_ID_PREFIX = 'custom_';
export const OKOBAUDAT_ID_PREFIX = 'ökobaudat_';

export interface IAnyBoolean {
  [key: string]: boolean;
}

export interface ProductCategories {
  Boverket?: IAnyBoolean;
  BK04?: IAnyBoolean;
  ILCD?: IAnyBoolean;
  Custom?: boolean;
  Library?: boolean;
}

export type ProductID = string;
export type ProductRecord = Record<ProductID, IProduct>;
export enum ProductSources {
  Boverket = 'Boverket',
  Custom = 'custom',
  Ökobaudat = 'ökobaudat',
  Nodon = 'Nodon',
}
/**
 * Use this in rare cases when the enum above doesn't work
 */
export type ProductSourcesUnion =
  (typeof ProductSources)[keyof typeof ProductSources];

/**
 * This is the product imported from Boverket, BK04 (ökobaudat) or created by the user.
 * Contains what's needed to calculate c02 emission based on a quantity.
 * Not to be confused with ProductElement
 */
export interface IProduct extends ModelTimestamps {
  /** Generic or EPD product id */
  id: ProductID;
  name: string;
  owner?: string;

  /**
   * Organizations that can access this product.
   * Undefined means it's a public product and all organizations can access it.
   */
  organizations?: string[];
  unit: QuantityUnit;
  description?: string;

  /** If the product is an EPD, the generic product id is stored here. Else it will be undefined */
  generic_id?: string;

  source: ProductSources;
  categories: ProductCategories;
  external_identifiers: Record<string, string>;

  /**
   * Building life-cycle stages cheatsheet
   *
   * A1-A3: Production
   *
   * A4: Transport
   *
   * A5: Installation / waste
   * */
  conversion_factors: ConversionFactors;

  /**
   * Material characteristics like thermal conductivity and heat capacity
   */
  characteristics: Characteristics;

  /**
   * The ID of the element category this product belongs to (optional).
   */
  category_id?: ElementCategoryID;

  /**
   * A snapshot of the properties of the element category at the time of creation.
   * Can be used to filter recipes based on the properties of the element category.
   */
  category_property_value_record: PropertyResolvedCountRecord;
}

export interface IProductElementArticle extends IProductElement {
  path: string[];

  quantity: number;
  co2e: number;
  cost: number;
}

export interface IProductElementParentItem {
  productId: ProductID;
  name: string;
  sums: Results;
  articles: IProductElementArticle[];

  co2eSum: number;
  costSum: number;
  disableProductSwitch: boolean;
  quantitiesSum: number;
  unit: QuantityUnit;
}

/**
 * Raw product from DB
 */
export type ProductJSON = Omit<
  IProduct,
  | 'created_at'
  | 'updated_at'
  | 'deleted_at'
  | 'description'
  | 'owner'
  | 'organizations'
> & {
  created_at: string;
  updated_at: string;
  deleted_at?: string | null;
  description?: string | null;
  owner?: string | null;
  organizations?: string[] | null;
  generic_id?: string | null;
};

type IFactoryProductBase = SemiPartial<IProduct, 'id' | 'name'>;

type IFactoryBoverketProduct = Replace<
  IFactoryProductBase,
  {
    source?: Extract<IProduct['source'], ProductSources.Boverket>;
  }
>;

type IFactoryNodonProduct = Replace<
  IFactoryProductBase,
  {
    source?: Extract<IProduct['source'], ProductSources.Nodon>;
  }
>;

type IFactoryOekobaudatProduct = Replace<
  IFactoryProductBase,
  {
    source?: Extract<IProduct['source'], ProductSources.Ökobaudat>;
  }
>;

export type IFactoryCustomProduct = Replace<
  IFactoryProductBase,
  {
    id?: IProduct['id'];
    source?: Extract<IProduct['source'], ProductSources.Custom>;
    organizations: NonNullable<IProduct['organizations']>;
  }
>;

export type IFactoryProduct =
  | IFactoryCustomProduct
  | IFactoryBoverketProduct
  | IFactoryNodonProduct
  | IFactoryOekobaudatProduct;

export type IFactoryProductSourceMap = {
  [ProductSources.Ökobaudat]: IFactoryOekobaudatProduct;
  [ProductSources.Nodon]: IFactoryNodonProduct;
  [ProductSources.Boverket]: IFactoryBoverketProduct;
  [ProductSources.Custom]: IFactoryCustomProduct;
};

export const REQUIRED_PRODUCT_PROPERTIES: Array<keyof IProduct> = [
  'categories',
  'characteristics',
  'conversion_factors',
  'created_at',
  'id',
  'name',
  'source',
  'unit',
  'updated_at',
];

export interface IProductListItem extends Omit<IProductElement, 'kind'> {
  kind: ElementKind.ProductItem;
  path: ElementPath;
  /**
   *
   * ProductListItem: same id as corresponding ProductElement
   */
  id: IProductElement['id'];
}

export interface IProductListGroup
  extends Omit<IProductElement, 'kind' | 'count' | 'product_id'> {
  kind: ElementKind.ProductGroup;
  /**
   *
   * ProductListGroup: same id as corresponding Product, prefixed with category id if parent is a category group.
   */
  id: string;
  /**
   * If parent is a category group, product id without prefix is stored here. Otherwise it will be undefined.
   */
  product_id?: string;
  elements: IProductListItem[];
}

export interface IProductListCategoryGroup {
  kind: ElementKind.ProductCategory;
  id: ElementCategoryID;
  unit: QuantityUnit;
  results: Results;
  elements: IProductListGroup[];
}

export type OneOfProductListGroups =
  | IProductListGroup
  | IProductListCategoryGroup;

export type OneOfSelectableProductListElements =
  | IProductListItem
  | IProductListGroup;
