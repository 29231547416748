import React, { FC, useCallback } from 'react';
import { Box, Button } from '@mui/material';
import { Publish } from '@mui/icons-material';
import ImportElementsDialog from '../../../components/ImportElements/ImportElementsDialog';
import { ExportObject, getJSONFromFile } from '../../../helpers/import_helpers';
import { useBooleanState } from '../../../hooks/hooks';
import { useSelectedOrganization } from '../../../store/organization';
import {
  useCreateProject,
  useFindFreeProjectName,
} from '../../../store/project';
import { validateProject } from '../../../../../shared/validation/project.validation';
import { useUserId } from '../../../hooks/user.hook';
import amplitudeLog from '../../../amplitude';
import { useUIState } from '../../../store/ui';
import { Project } from '../../../../../shared/models/project.interface';
import { useImportProject } from '../../../hooks/import.hook';
import { setElementExpanded } from '../../../hooks/expand-elements.hook';
import { isGreaterOrEqualVersion } from '../../../../../shared/helpers/utils.helpers';
import { useConfig } from '../../../providers/ConfigProvider';
import { FileWithPath } from 'react-dropzone/.';

const ImportProjectButton: FC = () => {
  const { selectedProjectFolderId } = useUIState('selectedProjectFolderId');
  const createProject = useCreateProject();
  const findFreeProjectName = useFindFreeProjectName();
  const importProject = useImportProject();
  const [config] = useConfig();

  const organization = useSelectedOrganization();
  const owner = useUserId();

  const [importDialog, showDialog, hideDialog] = useBooleanState(false);

  const closeDialog = useCallback(() => {
    hideDialog();
  }, [hideDialog]);

  const handleImport = useCallback(
    async (files: readonly FileWithPath[]): Promise<void> => {
      if (!files.length) {
        throw new Error('No files selected');
      }
      if (!organization) {
        throw new Error('Import error. Invalid user or organization');
      }

      for (const file of files) {
        const {
          recipes,
          version,
          project: jsonProject,
        }: ExportObject = await getJSONFromFile(file);

        const fileName = file.name.slice(0, file.name.indexOf('.json'));
        const name = findFreeProjectName(fileName);

        if (!jsonProject || !recipes || !name || !version) {
          throw new Error('Invalid file');
        }

        // Keep this up to date (bump if big model changes are made)
        if (
          !isGreaterOrEqualVersion(version, config.version, {
            patch: Number.MAX_SAFE_INTEGER,
          })
        ) {
          throw new Error(
            `Project version must be newer than "${config.version}", please re-export the project.`,
          );
        }

        let project: Project = {
          ...jsonProject,
          name,
          owner,
          parent_id: selectedProjectFolderId ?? null,
        };

        project = await importProject(project, recipes);

        validateProject(project);

        // Duplicate to make sure a template is not used (if there is one)
        const { id, parent_id } = await createProject(project, {
          successMessage: `Imported ${project.name}`,
          errorMessage: `Failed to import ${project.name}`,
        });

        if (parent_id) {
          setElementExpanded(parent_id, true, true);
        }
        amplitudeLog('Project Import', {
          ProjectId: id,
        });

        hideDialog();
      }
    },
    [
      organization,
      findFreeProjectName,
      config.version,
      owner,
      selectedProjectFolderId,
      importProject,
      createProject,
      hideDialog,
    ],
  );

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={showDialog}
      >
        <Box pr={4} display="flex">
          <Publish color="primary" fontSize="small" />
        </Box>
        New from file
      </Button>
      <ImportElementsDialog
        title="Import project"
        content="Drag JSON file here"
        acceptedFormats={['.json']}
        open={importDialog}
        onImport={handleImport}
        onClose={closeDialog}
      ></ImportElementsDialog>
    </>
  );
};

export default ImportProjectButton;
