import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { OneOfElementListElements } from '../../../../shared/models/project.interface';
import { DEFAULT_BAR_CHART_CO2_COLOR } from '../../../../shared/constants';
import SimpleBarChart, { ISimpleBarChartValue } from './SimpleBarChart';
import {
  useElementResultsOfAllProposals,
  useGetProposalColor,
  useProposalsUsingElement,
} from '../../hooks/proposals.hook';
import {
  ELEMENT_CO2_BAR_HEIGHT,
  ELEMENT_COST_BAR_HEIGHT,
} from '../ElementList/list.constants';
import {
  useElementMaxResults,
  useElementResults,
  useGetResultsPerGFA,
} from '../../hooks/results.hook';
import { useProjectCostIsDisabled } from '../../store/project';
import { getConversionFactorValue } from '../../../../shared/helpers/conversion-factors.helpers';
import { filterObject } from '../../../../shared/helpers/object_helpers';
import { isCostUnit } from '../../../../shared/helpers/unit_helpers';
import { ResultsTooltip } from '../ResultsTooltip';
import { getCo2eChartDataFromResults } from './bar-chart.helpers';

interface IElementBarChartsProps {
  element: OneOfElementListElements;
  color?: string;
  width?: number | string;
  dimmed?: boolean;
}

const ElementBarCharts: React.FC<IElementBarChartsProps> = ({
  element,
  width = '100%',
  dimmed,
  color = DEFAULT_BAR_CHART_CO2_COLOR,
}) => {
  const { classes, cx } = useStyles();
  const disableCost = useProjectCostIsDisabled();

  const getProposalColor = useGetProposalColor();
  const getResultsPerGFA = useGetResultsPerGFA();

  const proposals = useProposalsUsingElement(element);
  const proposalResults = useElementResultsOfAllProposals(element);
  const elementResults = useElementResults(element);
  const maxResults = useElementMaxResults();
  const maxResultsPerGFA = getResultsPerGFA(maxResults);
  const elementResultsPerGFA = getResultsPerGFA(elementResults);

  const co2ePerGFA = getConversionFactorValue(elementResultsPerGFA, 'co2e');
  const costFactorsPerGFA = useMemo(
    () => filterObject(elementResultsPerGFA, (value, key) => isCostUnit(key)),
    [elementResultsPerGFA],
  );
  const co2ePerGfaMax = getConversionFactorValue(maxResultsPerGFA, 'co2e');
  const costPerGfaMax = getConversionFactorValue(maxResultsPerGFA, 'sek');

  const firstProposals = useMemo(
    () => (proposals.length > 5 ? proposals.slice(0, 5) : proposals),
    [proposals],
  );

  if (!co2ePerGfaMax && !costPerGfaMax) {
    return <Typography variant="caption">0 kgCO2e, 0 SEK</Typography>;
  }

  return (
    <Box
      width={width}
      className={cx(classes.container, dimmed && classes.dimmed)}
    >
      {/* One chart per proposal refering to this element */}
      {firstProposals.map((proposal) => {
        const proposalResult = proposalResults[proposal.id];

        const resultsPerGFA = getResultsPerGFA(
          proposalResult ?? elementResults,
        );
        const co2Values: ISimpleBarChartValue[] =
          getCo2eChartDataFromResults(resultsPerGFA);

        return (
          !proposal.hidden && (
            <Box key={'wrapper-' + proposal.id} className={cx(classes.chart)}>
              <ResultsTooltip results={resultsPerGFA} perGFA={true}>
                <SimpleBarChart
                  key={proposal.id}
                  color={getProposalColor(proposal.id)}
                  value={co2Values}
                  maxValue={co2ePerGfaMax}
                  height={ELEMENT_CO2_BAR_HEIGHT}
                />
              </ResultsTooltip>
            </Box>
          )
        );
      })}

      {/* No proposals, show one for all */}
      {proposals.length === 0 && (
        <Box className={cx(classes.chart)}>
          <ResultsTooltip results={elementResultsPerGFA} perGFA={true}>
            <SimpleBarChart
              value={co2ePerGFA}
              maxValue={co2ePerGfaMax}
              color={color}
              height={ELEMENT_CO2_BAR_HEIGHT}
            />
          </ResultsTooltip>
        </Box>
      )}

      {/* Cost (same for all) */}
      <Box className={cx(classes.chart)}>
        {!disableCost && (
          <ResultsTooltip results={elementResultsPerGFA} perGFA={true}>
            <SimpleBarChart
              height={ELEMENT_COST_BAR_HEIGHT}
              value={costFactorsPerGFA}
              maxValue={costPerGfaMax}
              color="black"
            />
          </ResultsTooltip>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    display: 'block',
    height: 'auto',
  },
  dimmed: {
    opacity: 0.5,
  },
  chart: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: '2px',
    },
  },
}));

export default ElementBarCharts;
