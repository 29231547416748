import React, { useCallback, useMemo } from 'react';
import {
  Add,
  DeleteForever,
  ContentCopy,
  LibraryAddCheck,
  CopyAll,
} from '@mui/icons-material';
import { IElement } from '../../../../../../shared/models/project.interface';
import { isElement } from '../../../../../../shared/helpers/recursive_element_helpers';
import amplitudeLog from '../../../../amplitude';
import {
  useAddStandardElement,
  useDuplicateElement,
  useRemoveElements,
} from '../../../../store/project';
import { useAddElementVersion } from '../../../../hooks/element-version.hook';
import {
  useSelectElementByProposals,
  useAvailableProposals,
  useGetProposalColor,
  useProposals,
  useProposalsUsingElement,
  useRemoveElementFromProposals,
} from '../../../../hooks/proposals.hook';
import { IProposal } from '../../../../../../shared/models/proposals.interface';
import { isElementWithGeneratedChildren } from '../../../../../../shared/helpers/element_helpers';
import { useIsReadonly } from '../../../../hooks/user.hook';
import { KebabMenu } from '../KebabMenu';
import { KebabMenuProps, NodonMenuItemProps } from '../../menu.model';
import { NodonTheme } from '../../../../style';
import { isEnvironment } from '../../../../../../shared/helpers/environment.helpers';

interface IElementKebabMenuInput extends KebabMenuProps {
  element: IElement;
}

const ElementKebabMenu: React.FC<IElementKebabMenuInput> = ({
  element,
  anchor,
  onOpen,
  onClose,
}) => {
  const addElement = useAddStandardElement();
  const duplicateElement = useDuplicateElement();
  const selectElementByProposals = useSelectElementByProposals();
  const removeElementFromProposals = useRemoveElementFromProposals();
  const getProposalColor = useGetProposalColor();
  const addElementVersion = useAddElementVersion();
  const removeElements = useRemoveElements({ showConfirm: true });

  const readonly = useIsReadonly();
  const isGenerated = isElementWithGeneratedChildren(element);
  const allProposals = useProposals();
  const availableProposals = useAvailableProposals(element);
  const proposalsUsingElement = useProposalsUsingElement(element);

  const allProposalsActive =
    availableProposals.length === proposalsUsingElement.length;

  if (!element.id) {
    throw new Error('Element ID is required');
  }

  const handleRemoveElement = useCallback(
    () => removeElements(element.id),
    [element.id, removeElements],
  );

  const toggleProposal = useCallback(
    (proposal: IProposal, checked: boolean) => {
      if (!isElement(element)) {
        return;
      }
      if (checked) {
        selectElementByProposals(element, proposal);
      } else {
        removeElementFromProposals(element, proposal);
      }
    },
    [selectElementByProposals, element, removeElementFromProposals],
  );

  const items = useMemo(() => {
    // Can't add element to generated elements
    const nonGeneratedItems: NodonMenuItemProps[] = !isGenerated
      ? [
          {
            id: MenuItemId.ElementAdd,
            label: 'Add Element',
            icon: <Add color="secondary" />,
            onClick: async () => {
              await addElement(element);
              amplitudeLog('Element New', { Place: 'Nested' });
              onClose?.();
            },
          },
        ]
      : [];

    const generatedItems: NodonMenuItemProps[] = [
      {
        id: MenuItemId.ElementDuplicate,
        label: 'Duplicate',
        disabled: !allProposals.length,
        icon: <ContentCopy color="secondary" />,
        onClick: () => {
          duplicateElement(element);
          amplitudeLog('Element Duplicate', {
            ElementID: element.id,
          });
          onClose?.();
        },
      },
      {
        id: MenuItemId.ElementVersionAdd,
        label: 'New Version',
        disabled: !allProposals.length,
        icon: <CopyAll color="secondary" />,
        onClick: () => {
          addElementVersion(element);
          amplitudeLog('Element Version New', {
            ElementID: element.id,
          });
          onClose?.();
        },
      },
      {
        id: MenuItemId.ElementDelete,
        label: 'Delete ',
        icon: <DeleteForever color="secondary" />,
        onClick: handleRemoveElement,
      },
    ];

    const proposalItems: NodonMenuItemProps[] = availableProposals.map(
      (proposal) => {
        const checked = proposalsUsingElement.includes(proposal);
        return {
          id: `proposal.select[${element.id}][${proposal.id}]`,
          label: proposal.name,
          checked,
          checkboxColor: getProposalColor(proposal),
          onClick: () => {
            toggleProposal(proposal, !checked);
            onClose?.();
          },
        };
      },
    );

    const selectAllProposalItem: NodonMenuItemProps = {
      id: MenuItemId.ProposalSelectAll,
      label: 'All',
      divider: true,
      disabled: !availableProposals.length,
      checked: allProposalsActive,
      checkboxColor: NodonTheme.palette.secondary.main,
      onClick: () => {
        if (!allProposalsActive) {
          selectElementByProposals(element, availableProposals);
        } else {
          removeElementFromProposals(element, availableProposals);
        }
        onClose?.();
      },
    };

    const useInProposalItem: NodonMenuItemProps = {
      id: MenuItemId.ProposalUseInProposal,
      label: 'Use in Proposal',
      icon: <LibraryAddCheck color="secondary" />,
      items: [selectAllProposalItem, ...proposalItems],
    };

    return [...nonGeneratedItems, ...generatedItems, useInProposalItem].filter(
      isNotAllowedOnProduction,
    );
  }, [
    isGenerated,
    allProposals.length,
    handleRemoveElement,
    availableProposals,
    allProposalsActive,
    addElement,
    element,
    onClose,
    duplicateElement,
    addElementVersion,
    proposalsUsingElement,
    getProposalColor,
    toggleProposal,
    selectElementByProposals,
    removeElementFromProposals,
  ]);

  return (
    !readonly && (
      <KebabMenu
        id={element.id}
        items={items}
        tooltip="Element options"
        anchor={anchor}
        onOpen={onOpen}
        onClose={onClose}
      />
    )
  );
};

enum MenuItemId {
  ElementAdd = 'element.add',
  ElementDuplicate = 'element.duplicate',
  ElementDelete = 'element.delete',
  ElementVersionAdd = 'elementVersion.add',
  ProposalSelectAll = 'proposal.selectAll',
  ProposalUseInProposal = 'proposal.useInProposal',
}

const isNotAllowedOnProduction = (item: NodonMenuItemProps) => {
  if (isEnvironment('production')) {
    return ![MenuItemId.ElementVersionAdd].includes(item.id as MenuItemId);
  }
  return true;
};

export default ElementKebabMenu;
