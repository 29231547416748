import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { ButtonProps } from '@mui/material';
import {
  IElement,
  OneOfPropertyElements,
} from '../../../../../../shared/models/project.interface';
import {
  ElementSelectPropertyCountType,
  IElementPropertyOption,
  IElementSelectProperty,
} from '../../../../../../shared/models/element_property.interface';
import { useElementPropertiesUtils } from '../../../../hooks/element-properties.hook';
import { isElementQuantitySelectProperty } from '../../../../../../shared/helpers/element_quantity_helpers';
import { useUpdateQuantity } from '../../../../hooks/quantity-properties.hook';
import { isElement } from '../../../../../../shared/helpers/recursive_element_helpers';
import TextMenu from '../../../menus/TextMenu/TextMenu';
import { labelFromValue } from '../../../../../../shared/helpers/element_property_factory_helpers';
import {
  getCount,
  hasCount,
  getSelectPropertyOptions,
  isElementSbefProperty,
  getCountTooltip,
} from '../../../../../../shared/helpers/element_property_helpers';
import { NodonTheme } from '../../../../style';
import SelectMenuItem from '../../../menus/ListItems/SelectMenuItem';
import { SelectListChildren } from '../../../menus/menu.model';
import { getListItemKey } from '../../../../../../shared/helpers/utils.helpers';

interface ElementPropertySelectProps {
  property: IElementSelectProperty;
  element: OneOfPropertyElements;
}

const ElementPropertySelect: React.FC<ElementPropertySelectProps> = ({
  property,
  element,
}) => {
  const count = getCount(property, false);
  const isFallback = !hasCount(property);

  const { updateProperty } = useElementPropertiesUtils(element);
  const updateQuantity = useUpdateQuantity();

  const isSbefProperty = isElementSbefProperty(property);
  const isSmallMenuWidth = !isSbefProperty && property.options.length < 20;
  const labelMaxLength = isSmallMenuWidth ? 25 : 40;

  const [anchor, setAnchor] = useState<Element>();
  const [buttonLabel, setButtonLabel] = useState('');
  const [disableDivider, setDisableDivider] = useState(false);

  const options = useMemo(() => getSelectPropertyOptions(property), [property]);

  const menuButtonProps = useMemo<ButtonProps>(() => {
    return {
      sx: {
        color: NodonTheme.typography[isFallback ? 'caption' : 'body2'].color,
        maxWidth: 175,
      },
    };
  }, [isFallback]);

  const update = useCallback(
    async (
      modified: Partial<IElementSelectProperty>,
    ): Promise<IElement | undefined> => {
      if (isElement(element) && isElementQuantitySelectProperty(property)) {
        await updateQuantity(element, [
          {
            ...modified,
            name: property.name,
          },
        ]);
      } else {
        return updateProperty({ ...property, ...modified }).then(
          ({ element }) => element,
        );
      }
    },
    [updateQuantity, updateProperty, property, element],
  );

  const handleItemSelect = useCallback(
    async (newValue: ElementSelectPropertyCountType) => {
      if (newValue && newValue !== count) {
        setAnchor(undefined);
        await update({ count: newValue === 'none' ? undefined : newValue });
      }
    },
    [count, update],
  );

  const handleCloseMenu = useCallback(() => {
    setAnchor(undefined);
  }, [setAnchor]);

  const handleSearch = useCallback(
    (searchString: string) => {
      setDisableDivider(searchString.length > 0);
    },
    [setDisableDivider],
  );

  const renderOptions = useCallback<SelectListChildren<IElementPropertyOption>>(
    ({ label, value }, style, index) => (
      <SelectMenuItem
        key={getListItemKey(index, label)}
        style={style}
        label={label}
        value={value}
        disableDivider={disableDivider}
        indentAfterFirstWord={isSbefProperty}
        selected={label === count}
        showTooltip={label.length > labelMaxLength}
        onSelect={handleItemSelect}
      />
    ),
    [disableDivider, isSbefProperty, count, labelMaxLength, handleItemSelect],
  );

  useEffect(() => {
    setButtonLabel(typeof count === 'string' ? labelFromValue(count) : '');
  }, [count, property.count]);

  return (
    <TextMenu
      items={options}
      anchor={anchor}
      label={buttonLabel}
      buttonProps={menuButtonProps}
      menuWidth={isSmallMenuWidth ? 'small' : 'medium'}
      tooltip={getCountTooltip(property)}
      onOpen={setAnchor}
      onClose={handleCloseMenu}
      onSearch={handleSearch}
    >
      {renderOptions}
    </TextMenu>
  );
};

export default ElementPropertySelect;
